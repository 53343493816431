import React, { useEffect, useState } from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const SourceOfFundsForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Source of Funds</Label>
        <InputForm
          {...register("sourceOfFunds", {
            required: {
              value: true,
              message: "Source of Funds is required",
            },
          })}
          className="w-full"
          name="sourceOfFunds"
        />
      </div>
      <div className="space-y-2">
        <Label required>Type</Label>
        <div className="flex space-x-6">
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("type")}
              name="type"
              value="exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Public
            </span>
          </label>
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("type")}
              name="type"
              value="non-exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Private
            </span>
          </label>
        </div>
      </div>
      <div className="space-y-2">
        <Label required>Category</Label>
        <div className="flex space-x-6">
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("category")}
              name="category"
              value="exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Domestic
            </span>
          </label>
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("category")}
              name="category"
              value="non-exists"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              International
            </span>
          </label>
        </div>
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default SourceOfFundsForm;
