import { useMutation, useQuery } from '@tanstack/react-query';
import { MAIN_ACTIVITY } from 'services';

export const useActivityList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal }) => {
      return MAIN_ACTIVITY.getActivitiesList(signal);
    },
    ...options,
  });
};

export const useActivityDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return MAIN_ACTIVITY.getActivityById(id, signal);
    },
    ...options,
  });
};

export const useActivity = () => {
  return useMutation((payload) => MAIN_ACTIVITY.getActivities(payload));
};

export const usePostActivity = () => {
  return useMutation((payload) => MAIN_ACTIVITY.postActivity(payload));
};

export const useUpdateActivity = () => {
  return useMutation((payload) => MAIN_ACTIVITY.updateActivity(payload));
};

export const useDeleteActivity = () => {
  return useMutation((id) => MAIN_ACTIVITY.deleteActivity(id));
};
