import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { findIndex, last } from "lodash";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ParameterForm,
} from "components";
import { FormModal } from "components/Modal";
import { useNavigate, useParams } from "react-router";
import InformationForm from "./informationForm";
import SubThemeForm from "./subThemeForm";
import SubThemeItem from "./subThemeItem";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { notification } from "antd";

const ThematicProgrammesForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadDetailDataTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataTP = () => {
    // setLoading(true);
    axios
      .get(`${MASTER_DATA_URL}/ThematicProgrammes/${params.id}`)
      .then((res) => {
        let tp = res.data?.data;
        methods.setValue("numberingFormat", tp?.numberingFormat);
        methods.setValue("nama", tp?.nama);
        methods.setValue("deskripsi", tp?.deskripsi);
        methods.setValue("subTheme", tp?.subTheme);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const onFinishForm = () => {
    let payload = methods.getValues();
    payload.id = params.id;

    if (params.id) {
      axios
        .put(`${MASTER_DATA_URL}/ThematicProgrammes`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil mengubah data Thematic Programmes!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    } else {
      axios
        .post(`${MASTER_DATA_URL}/ThematicProgrammes`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil menambah data Thematic Programmes!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    }
  };

  const methods = useForm({
    defaultValues: {
      format_number: "#0",
      nama: "",
      deskripsi: "",
      subTheme: [],
    },
  });
  const { append, update, remove, fields } = useFieldArray({
    control: methods?.control,
    name: "subTheme",
  });

  const subThemeMethods = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const [subThemeModal, setSubThemeModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!subThemeModal) {
      setIsEdit(false);
      subThemeMethods.reset({
        name: "",
        description: "",
      });
    }
    return;
  }, [methods.getValues("subTheme"), subThemeModal]);

  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      label: params.id
        ? "Edit Thematic Programmes"
        : "Tambah Thematic Programmes",
    },
  ];

  const onSubmitSubTheme = (data) => {
    if (data) {
      if (isEdit) {
        const index = findIndex(fields, (field) => field.id === data.id);
        update(index, data);
      } else {
        append(data);
      }
      setSubThemeModal(false);
    }
  };

  const onEditSubTheme = (data) => {
    setSubThemeModal(true);
    setIsEdit(true);
    subThemeMethods.reset(data);
  };

  const onDeleteSubTheme = (data) => {
    const index = findIndex(fields, (field) => field.id === data.id);
    remove(index);
  };

  return (
    <div>
      <FormModal
        title={isEdit ? "Edit Sub Theme" : "Tambah Sub Theme"}
        open={subThemeModal}
        onClose={setSubThemeModal}
        onSubmit={() => subThemeMethods.handleSubmit(onSubmitSubTheme)()}
      >
        <FormProvider {...subThemeMethods}>
          <SubThemeForm />
        </FormProvider>
      </FormModal>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>
              <div>
                {params.id
                  ? "Edit Thematic Programmes"
                  : "Tambah Thematic Programmes"}
              </div>
            </CardHeader>
            <CardBody>
              <InformationForm />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>Sub Theme</div>
                <div>
                  <Button
                    className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                    startIcon={<AiOutlinePlus className="text-white" />}
                    onClick={() => setSubThemeModal(true)}
                  >
                    Tambah Sub Theme
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <SubThemeItem
                datas={fields}
                onEdit={onEditSubTheme}
                onDelete={onDeleteSubTheme}
              />
            </CardBody>
          </Card>
          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
              onClick={() => {
                onFinishForm();
              }}
            >
              Simpan
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ThematicProgrammesForm;
