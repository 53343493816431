import { debounce, map } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";

const Indicator = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginator, setPaginator] = useState();
  const [loading, setLoading] = useState(true);

  const links = [
    {
      // path: "/theory-of-change",
      label: "Theory of Change",
    },
    {
      label: "Indicator",
    },
  ];

  const [indicator, setIndicator] = useState([])

  const loadDataIndicator = () => {
    setLoading(true);

    let path = `${MASTER_DATA_URL}/Indicator/datatable`
    let payload = {
      "generalSearch": keyword,
      "searches": [
        {
          "field": "",
          "value": ""
        }
      ],
      "sorts": [
        {
          "field": "",
          "order": ""
        }
      ],
      "pageNumber": page,
      "pageSize": limit
    }

    axios.post(path, payload)
      .then(res => {
        setIndicator(res.data?.data?.data);

        setPaginator({
          page: res.data?.data?.currentPage,
          limit: res.data?.data?.pageSize,
          total: res.data?.data?.totalCount,
          previous_pages: res.data?.data?.currentPage > 1,
          next_pages: res.data?.data?.currentPage < res.data?.data?.totalPages,
        });
        setLoading(false);
      });
  };

  useMemo(() => {
    loadDataIndicator();
  }, [keyword, page, limit])

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        width: 70,
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "type",
        title: "Type",
        dataIndex: "type",
        width: 150,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full">
            {value}
          </div>
        ),
      },
      {
        id: "resultStatement",
        title: "Result Statement",
        dataIndex: "resultStatement",
        width: 200,
        className: "max-w-[250px] break-words",
        sortable: true,
        render: (value, data) => (
          <div className="w-full break-words" style={{whiteSpace: "initial"}}>
            {data.numberingFormat} {value}
          </div>
        ),
      },
      {
        id: "indicator",
        title: "Indicator",
        // dataIndex: "indicator",
        // width: 300,
        // className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div>
            {map(data?.indicator ?? [], (item, key) => (
              <div key={key} className="flex items-center space-x-2">
                <div>{item?.numberingFormat}</div>
                <div>{item?.nama}</div>
              </div>
            ))}
          </div>
        ),
      },
      {
        id: "parameters",
        title: "Parameter",
        dataIndex: "parameters",
        width: 540,
        // className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) =>
          data?.indicator?.length > 0 ? (
            <div className="divide-y">
              {map(data?.indicator ?? [], (indicator, key) => (
                <div >
                  {map(indicator.parameter ?? [], (item, key) => (
                    <div
                      key={key}
                      className={`flex items-center ${item.length > 1 && "py-3"
                        }`}
                    >
                      {item.nama}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            "-"
          ),
      },
    ];
  }, [indicator]);

  return (
    <BasicList
      links={links}
      title={"Indicator"}
      onSearch={onSearch}
      columns={tableColumns}
      dataSource={indicator}
      isLoading={loading}
      onChangePage={setPage}
      onChangeRowsPerPage={setLimit}
      pagination={paginator}
    />
  );
};

export default Indicator;
