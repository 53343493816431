import React from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { Header, Sidebar } from "components";

const BasicLayout = ({ children }) => {
  const { token, user } = useSelector((state) => state.auth);

  if (!token || !user) {
    return <Navigate to="/unauthenticated" replace />;
  }

  return (
    <div>
      <div className="flex flex-col h-full">
        <div className="flex flex-1 overflow-hidden">
          <Sidebar />

          <div className="flex-1">
            <Header />
            <motion.main
              className="flex-1 overflow-y-auto paragraph p-8 pt-[5rem] bg-gray-50 w-screen md:w-[calc(100vw-15rem)]"
              key={window.location.pathname}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.05 }}
            >
              {children}
            </motion.main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicLayout;
