import http from 'app/interceptors';

export const getDetailActivitiesList = async (signal) => {
  const { data } = await http.get(`/DetailKegiatan/list`, { signal });
  return data;
};

export const getDetailActivities = async (payload) => {
  const { data } = await http.post(`/DetailKegiatan/datatable`, payload);
  return data;
};

export const getDetailActivityById = async (id, signal) => {
  const { data } = await http.get(`/DetailKegiatan/${id}`, { signal });
  return data;
};

export const postDetailActivity = async (payload) => {
  const { data } = await http.post(`/DetailKegiatan`, payload);
  return data;
};

export const updateDetailActivity = async (payload) => {
  const { data } = await http.put(`/DetailKegiatan`, payload);
  return data;
};

export const deleteDetailActivity = async (id, signal) => {
  const { data } = await http.delete(`/DetailKegiatan`, {
    params: { id },
    signal,
  });
  return data;
};
