import React, { Fragment } from 'react'

const CardHeader = ({children}) => {
  return (
    <Fragment>
      <div className="px-6 py-5 text-lg font-semibold text-[#101828] border-t-[1px] border-gray-200">
        {children}
      </div>
    </Fragment>
  )
}

export default CardHeader