import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";

import { Breadcrumbs, Card, CardBody, CardHeader } from "components";
import { useActivityDetail } from "hooks/mainActivity";

import MainActivityForm from "./Form";
import { accountType } from "constants";

const breadcrumbs = [
  {
    path: "/master-data",
    label: "Master Data",
  },
  {
    path: "/master-data/main-activity",
    label: "Kegiatan Utama",
  },
  {
    label: "Detail Kegiatan Utama",
  },
];

const MainActivityDetail = () => {
  const { id } = useParams();

  const { data, isLoading } = useActivityDetail([`main-activity/${id}`, id]);

  const form = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (!isLoading && data?.data) {
      const mainActivity = data.data;

      form.setValue("code", mainActivity.code);
      form.setValue("kegiatanUtama", mainActivity.kegiatanUtama);
      form.setValue("deskripsi", mainActivity.deskripsi);

      form.setValue(
        "accountType",
        accountType.find(({ value }) => value === mainActivity.accountType)
      );
    }
  }, [data?.data, form, isLoading]);

  return (
    <div className="pt-12 space-y-6">
      <Breadcrumbs items={breadcrumbs} />

      <Card>
        <CardHeader>Detail Kegiatan Utama</CardHeader>
        <CardBody>
          <FormProvider {...form}>
            <MainActivityForm mode="detail" detail={data?.data ?? {}} />
          </FormProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default MainActivityDetail;
