import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";

import { accountType } from "constants";
import { Breadcrumbs, Card, CardBody, CardHeader } from "components";
import { useDetailActivityDetail } from "hooks/detailActivity";

import DetailActivityForm from "./Form";

const breadcrumbs = [
  {
    path: "/master-data",
    label: "Master Data",
  },
  {
    path: "/master-data/detail-activity",
    label: "Detail Kegiatan",
  },
  {
    label: "Detail Kegiatan",
  },
];

const DetailActivityDetail = () => {
  const { id } = useParams();

  const { data, isLoading } = useDetailActivityDetail([
    `detail-activity/${id}`,
    id,
  ]);

  const form = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (!isLoading && data?.data) {
      const detailActivity = data.data;

      form.setValue("code", detailActivity.code);
      form.setValue("detail", detailActivity.detail);
      form.setValue("deskripsi", detailActivity.deskripsi);

      form.setValue("idKegiatanUtama", {
        value: detailActivity.idKegiatanUtama,
        label: detailActivity.kegiatanUtama,
      });

      form.setValue(
        "accountType",
        accountType.find(({ value }) => value === detailActivity.accountType)
      );
    }
  }, [data?.data, form, isLoading]);

  return (
    <div className="pt-12 space-y-6">
      <Breadcrumbs items={breadcrumbs} />

      <Card>
        <CardHeader>Detail Kegiatan</CardHeader>
        <CardBody>
          <FormProvider {...form}>
            <DetailActivityForm mode="detail" detail={data?.data ?? {}} />
          </FormProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailActivityDetail;
