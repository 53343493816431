import React from "react";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate } from "react-router";

const InvestmentCriteriaDetail = () => {
  const navigate = useNavigate();
  const links = [
    {
      path: "/investment-criteria",
      label: "Investment Strategy",
    },
    {
      path: "/investment-strategy/investment-criteria",
      label: "Investment Criteria",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <span
        className="flex items-center space-x-2 cursor-pointer text-gray-600"
        onClick={() => navigate(-1)}
      >
        <CgArrowLeft className="text-lg" />
        <p className="font-bold text-sm">Back to Investment Criteria</p>
      </span>
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-1">
              <Label>Investment Criteria</Label>
              <p className="text-sm text-gray-800">
                Lorem Ipsum Dolor Sit Amet
              </p>
            </div>
            <div className="space-y-1">
              <Label>Deskripsi</Label>
              <p className="text-sm text-gray-800">
                Lorem Ipsum Dolor Sit Amet
              </p>
            </div>
            <div className="space-y-5">
              <TitleText className="text-primary-700 font-bold">
                Indicator
              </TitleText>
              <table className="table w-full text-gray-600">
                <thead className="border-b">
                  <tr>
                    <th className="bg-primary-50">No</th>
                    <th className="bg-primary-50">Indicator</th>
                    <th className="bg-primary-50">Deskripsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td>#0.1</td>
                    <td>Indicator A</td>
                    <td>Lorem Ipsum Dolor Sit Amet</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default InvestmentCriteriaDetail;
