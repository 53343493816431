import React, { Fragment } from "react";
import {
  Routes as WrapperRoutes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import _ from "lodash";

import { BasicLayout } from "components/Layout";
import My404Component from "pages/My404Component";

import route from "./route";

const Routes = () => {
  return (
    <Router>
      <WrapperRoutes>
        {_.map(route, (val, key) => {
          const Component = val.element;
          const Layout = val.routeType === "public" ? BasicLayout : Fragment;

          return (
            <Route
              key={key}
              path={val.path}
              exact={true}
              element={
                <Layout>
                  <Component />
                </Layout>
              }
            />
          );
        })}
        <Route path="*" element={<My404Component />} />
      </WrapperRoutes>
    </Router>
  );
};

export default Routes;
