import React from "react";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import Form from "./form";

const FinancialRatioValueSetValue = () => {
  const navigate = useNavigate();
  const links = [
    {
      path: "/master-data",
      label: "Master Data",
    },
    {
      path: "/master-data/financial-ratio-value",
      label: "Pengaturan Nilai Ratio",
    },
    {
      label: "Atur Nilai Rasio",
    },
  ];
  const methods = useForm({
    defaultValues: {
      ratio_type: "Rasio Profitabilitas",
      main_purpose: "Mencuri Keuntungan",
      ratio: [
        {
          ratio_name: "Return On Assets",
          min_value: 1000,
        },
        {
          ratio_name: "Return On Equity",
          min_value: "",
        },
      ],
      standard_min_value: 2000,
    },
  });

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <Card>
        <CardHeader>
          <div>Atur Nilai Rasio</div>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <Form />
          </FormProvider>
        </CardBody>
      </Card>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <Button
          type="button"
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
          onClick={() => navigate(-1)}
        >
          Batal
        </Button>
        <Button
          type="button"
          className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
        >
          Simpan
        </Button>
      </div>
    </div>
  );
};

export default FinancialRatioValueSetValue;
