import http from 'app/interceptors';

export const getImpacts = async (params, signal) => {
  const { data } = await http.post(`/Impact/datatable`, { params, signal });
  return data;
};

export const getDatatableImpacts = async (payload) => {
  const { data } = await http.post(`/Impact/datatable`, payload);
  return data;
};

export const getImpactById = async (id, signal) => {
  const { data } = await http.get(`/Impact/${id}`, { signal });
  return data;
};

export const postImpact = async (payload) => {
  const { data } = await http.post(`/Impact`, payload);
  return data;
};

export const updateImpact = async (payload) => {
  const { data } = await http.put(`/Impact`, payload);
  return data;
};

export const deleteImpact = async (id, signal) => {
  const { data } = await http.delete(`/Impact`, { params: { id }, signal });
  return data;
};
