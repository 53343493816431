import React from "react";
import { Button, Label } from "components";
import { InputForm } from "components/HookForm";
import { BiTrash } from "react-icons/bi";
import { HiPlus } from "react-icons/hi";
import { useFormContext } from "react-hook-form";
import { map } from "lodash";

const AnswerItem = () => {
  const { register, watch, getValues } = useFormContext();
  return (
    <div className="space-y-3">
      {map(getValues("answerValue"), (answer_value, key) => (
        <div key={key} className="flex items-center space-x-[10px]">
          <div className="flex flex-1 items-center border border-gray-300 bg-gray-50 space-x-4 rounded-md px-4 py-3">
            <input
              {...register("answer_value")}
              type={watch("type") === "option" ? "radio" : "checkbox"}
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              name="answer_value"
              value="option"
            />
            <InputForm className="w-full" name="answer_input_value" />
          </div>
          <Button
            type="button"
            className="text-white border-success-500 bg-success-500 hover:bg-primary-700 hover:border-primary-700"
          >
            <HiPlus />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default AnswerItem;
