import { debounce, isEmpty } from "lodash";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";
import { useDeleteImpact, useImpacts } from "hooks/impact";
import { ConfirmationModal } from "components/Modal";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

const links = [
  {
    // path: "/theory-of-change",
    label: "Theory of Change",
  },
  {
    label: "Impact",
  },
];

const Impact = () => {
  const navigate = useNavigate();
  const datatatableImpacts = useImpacts();
  const deleteImpact = useDeleteImpact();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);

  const paginator = useMemo(() => {
    return {
      page: page,
      limit: page,
      total: total,
      previous_pages: total > limit,
      next_pages: total > limit,
    };
  }, [page, total, limit]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "numberingFormat",
        title: "#",
        dataIndex: "numberingFormat",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "nama",
        title: "nama",
        dataIndex: "nama",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Deskripsi",
        dataIndex: "deskripsi",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() =>
                navigate(`/theory-of-change/impact/${data.id}/detail`)
              }
            />

            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() =>
                navigate(`/theory-of-change/impact/${data.id}/form`)
              }
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                setIsOpen(true);
                setDeleteId(data?.id);
              }}
            />
          </div>
        ),
      },
    ];
  }, []);

  // requests
  const getDatatableImpact = (data) => {
    if (isEmpty(data)) return;

    datatatableImpacts.mutate(
      {
        pageNumber: page,
        pageSize: limit,
        generalSearch: keyword,
      },
      {
        onSuccess: (result) => {
          setTotal(result.data.totalCount);
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error?.message,
            variant: "error",
          });
        },
      }
    );
  };

  const onDelete = useCallback((id) => {
    deleteImpact.mutate(id, {
      onSuccess: () => {
        getDatatableImpact(paginator);
        setDeleteId(null);
        setIsOpen(false);
        enqueueSnackbar({
          message: "Data Impact berhasil dihapus",
          variant: "success",
        });
        setPage(1);
      },
      onError: (error, variables) => {
        setDeleteId(null);
        setIsOpen(false);
        console.log(error, variables);
      },
    });
  }, []);

  // cycle
  useEffect(() => {
    getDatatableImpact(paginator);
  }, [page, limit, keyword]);

  return (
    <>
      <BasicList
        links={links}
        title={"Impact"}
        onCreate={() => navigate("/theory-of-change/impact/form")}
        onSearch={onSearch}
        columns={tableColumns}
        dataSource={datatatableImpacts?.data?.data?.data ?? []}
        isLoading={datatatableImpacts.isLoading}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        pagination={paginator}
      />

      <ConfirmationModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setDeleteId(null);
        }}
        onSubmit={() => onDelete(deleteId)}
      ></ConfirmationModal>
    </>
  );
};

export default Impact;
