import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { BasicList } from "components/ListPage";
import { FormModal } from "components/Modal";

import FinancialInstrumentForm from "./Form";

const FinancialInstrument = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [formModal, setFormModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const links = [
    {
      path: "/investment-strategy",
      label: "Investment Strategy",
    },
    {
      label: "Financial Instrument",
    },
  ];

  const methods = useForm({
    defaultValues: {
      format_number: "#0",
      impact: "",
      description: "",
      indicators: [],
    },
  });

  const result = useMemo(() => {
    return [
      {
        code: 12,
        financialInstrument: "Financial Instrument 1",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 142,
        financialInstrument: "Financial Instrument 2",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 112,
        financialInstrument: "Financial Instrument 3",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 512,
        financialInstrument: "Financial Instrument 4",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 162,
        financialInstrument: "Financial Instrument 5",
        description: "Lorem ipsum Dolor Sit Amet",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const onCreate = () => {};
  const onEdit = (data) => {
    setFormModal(true);
    methods.reset(data);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "financialInstrument",
        title: "Financial Instrument",
        dataIndex: "financialInstrument",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Deskripsi",
        dataIndex: "description",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onEdit(data)}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => null}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div>
      <FormModal
        title={"Tambah Financial Instrument"}
        open={formModal}
        onClose={setFormModal}
        onSubmit={() => methods.handleSubmit(onCreate)()}
      >
        <FormProvider {...methods}>
          <FinancialInstrumentForm />
        </FormProvider>
      </FormModal>
      <BasicList
        links={links}
        title={"Financial Instrument"}
        onCreate={() => setFormModal(true)}
        onSearch={onSearch}
        columns={tableColumns}
        dataSource={result}
        // isLoading={status === "loading"}
        // onChangePage={setNewPage}
        // onChangeRowsPerPage={setNewLimit}
        pagination={paginator}
      />
    </div>
  );
};

export default FinancialInstrument;
