import React from "react";

import { accountType } from "constants";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";

const InformationForm = () => {
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Format Penomoran</Label>
        <InputForm className="w-full" name="numberingFormat" />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Output</Label>
        <InputForm className="w-full" name="nama" />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="deskripsi" />
      </div>
      <div className="flex gap-4">
        <div className="space-y-2 flex-1">
          <Label required>Kode COA</Label>
          <InputForm className="w-full" name="coaCode" />
        </div>
        <div className="space-y-2 flex-1">
          <Label required>Tipe COA</Label>
          <CustomSelectForm
            name="accountType"
            placeholder="Type"
            options={accountType}
          />
        </div>
      </div>
    </div>
  );
};

export default InformationForm;
