import React, { useEffect, useState } from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const CapacityAssessmentForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Penilaian Kapasitas</Label>
        <InputForm className="w-full" name="capacity_assessment" />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deksripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default CapacityAssessmentForm;
