import http from 'app/interceptors';

export const getActivitiesList = async (signal) => {
  const { data } = await http.get(`/KegiatanUtama/list`, { signal });
  return data;
};

export const getActivities = async (payload) => {
  const { data } = await http.post(`/KegiatanUtama/datatable`, payload);
  return data;
};

export const getActivityById = async (id, signal) => {
  const { data } = await http.get(`/KegiatanUtama/${id}`, { signal });
  return data;
};

export const postActivity = async (payload) => {
  const { data } = await http.post(`/KegiatanUtama`, payload);
  return data;
};

export const updateActivity = async (payload) => {
  const { data } = await http.put(`/KegiatanUtama`, payload);
  return data;
};

export const deleteActivity = async (id, signal) => {
  const { data } = await http.delete(`/KegiatanUtama`, {
    params: { id },
    signal,
  });
  return data;
};
