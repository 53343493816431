import React from "react";
import { BsDot } from "react-icons/bs";
import { map } from "lodash";
import { BiPencil, BiTrash } from "react-icons/bi";

const IndicatorItem = ({ datas = [], onEdit, onDelete }) => {
  return (
    <div>
      {datas?.length > 0 ? (
        <div className="space-y-4 my-6">
          {map(datas, (data, key) => (
            <div
              className="border border-gray-200 rounded-md px-2 py-2 flex space-x-3"
              key={key}
            >
              <div className="flex flex-1 items-center space-x-2">
                <div>
                  <BsDot className="text-gray-400 text-4xl" />
                </div>
                <div className="flex flex-col flex-1">
                  <div className="text-sm text-gray-800">{data?.indicator}</div>
                  <div className="text-xs text-gray-500">
                    {data?.description}
                  </div>
                </div>
              </div>
              <div className="flex flex-none items-center space-x-3 pr-2">
                <BiPencil
                  className="flex-1 text-gray-600 cursor-pointer"
                  onClick={() => onEdit(data)}
                />
                <BiTrash
                  className="flex-1 text-gray-600 cursor-pointer"
                  onClick={() => onDelete(data)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-400 my-8">
          No data has been added yet
        </div>
      )}
    </div>
  );
};

export default IndicatorItem;
