import React, { useEffect, useState } from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const FinancialInstrumentForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Financial Instrument</Label>
        <InputForm
          {...register("financialInstrument", {
            required: {
              value: true,
              message: "Financial Instrument is required",
            },
          })}
          className="w-full"
          name="financialInstrument"
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Thematic Programmes</Label>
        <CustomSelectForm
          name={`thematicProgrammes`}
          placeholder="Thematic Programmes"
          options={[
            { value: 1, label: "Satu" },
            { value: 2, label: "Dua" },
            { value: 3, label: "Tiga" },
          ]}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default FinancialInstrumentForm;
