import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { findIndex, isEmpty } from "lodash";

import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ParameterForm,
  Spinner,
} from "components";
import { FormModal } from "components/Modal";
import IndicatorForm from "./indicatorForm";
import IndicatorItem from "./indicatorItem";
import InformationForm from "./informationForm";
import { useNavigate } from "react-router";
import LoadingButton from "components/LoadingButton";
import { RiSave3Line } from "react-icons/ri";
import { useImpactDetail, useUpdateImpact } from "hooks/impact";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

const links = [
  {
    path: "/theory-of-change",
    label: "Theory of Change",
  },
  {
    path: "/theory-of-change/impact",
    label: "Impact",
  },
  {
    label: "Edit Impact",
  },
];

const ImpactEdit = ({ id }) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { status, data: dataImpact } = useImpactDetail(["impact", id]);
  const updateImpact = useUpdateImpact();

  const methods = useForm({
    defaultValues: {
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      indicator: [],
    },
  });

  const {
    fields: fieldsIndicator,
    append: appendIndicator,
    remove: removeIndicator,
    update: updateIndicator,
  } = useFieldArray({
    control: methods.control,
    name: "indicator",
  });

  useEffect(() => {
    if (dataImpact?.data) {
      const { data } = dataImpact;

      methods.setValue("numberingFormat", data?.numberingFormat);
      methods.setValue("nama", data?.nama);
      methods.setValue("deskripsi", data?.deskripsi);
      methods.setValue("indicator", data?.indicator);
    }
  }, [dataImpact, methods]);

  const indicatorMethods = useForm({
    defaultValues: {
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      parameter: [],
    },
  });

  const [indicatorModal, setIndicatorModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!indicatorModal) {
      indicatorMethods.reset({
        numberingFormat: "",
        nama: "",
        deskripsi: "",
        parameter: [],
      });
    }

    !indicatorModal && setIsEdit(false);

    return;
  }, [indicatorModal, indicatorMethods]);

  const onCreateIndicator = (payload) => {
    indicatorMethods.reset({
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      parameter: [],
    });

    setIndicatorModal(false);
    if (isEdit) {
      const index = findIndex(
        fieldsIndicator,
        (field) => field.id === payload.id
      );
      updateIndicator(index, payload);
    } else {
      appendIndicator(payload);
    }
    setIndicatorModal(false);
  };

  const onEditIndicator = (data) => {
    setIsEdit(true);
    indicatorMethods.reset({
      ...data,
    });
    setIndicatorModal(true);
  };

  const onDeleteIndicator = (index) => {
    removeIndicator(index);
  };

  const onSubmit = (data) => {
    if (isEmpty(data)) return;

    updateImpact.mutate(
      { ...data, id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("impact/list");

          enqueueSnackbar({
            message: "Impact berhasil diupdate",
            variant: "success",
          });

          navigate("/theory-of-change/impact");
        },
        onError: (error, variables) => {
          console.log(error, variables);
        },
      }
    );
  };

  return (
    <>
      <FormModal
        title={isEdit ? "Edit Indicator" : "Tambah Indicator"}
        open={indicatorModal}
        onClose={setIndicatorModal}
        onSubmit={() => indicatorMethods.handleSubmit(onCreateIndicator)()}
      >
        <FormProvider {...indicatorMethods}>
          <div className="space-y-5">
            <IndicatorForm open={indicatorModal} />
            <ParameterForm open={indicatorModal} />
          </div>
        </FormProvider>
      </FormModal>

      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          {status === "loading" ? (
            <Spinner />
          ) : (
            <>
              <Card>
                <CardHeader>
                  <div>Edit Impact</div>
                </CardHeader>
                <CardBody>
                  <InformationForm />
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <div className="flex items-center">Indicator</div>
                </CardHeader>
                <CardBody>
                  <IndicatorItem
                    fields={fieldsIndicator}
                    onEdit={onEditIndicator}
                    onDelete={onDeleteIndicator}
                  />
                </CardBody>
                <CardFooter>
                  <div className="flex items-center justify-end">
                    <Button
                      className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                      startIcon={<AiOutlinePlus />}
                      onClick={() => setIndicatorModal(true)}
                    >
                      Tambah Indicator
                    </Button>
                  </div>
                </CardFooter>
              </Card>
              <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
                  onClick={() => navigate(-1)}
                >
                  Batal
                </Button>
                <LoadingButton
                  type="button"
                  className="button-primary with-loading w-60"
                  endIcon={<RiSave3Line className="text-white" size={20} />}
                  onClick={() => methods.handleSubmit(onSubmit)()}
                  isLoading={updateImpact.status === "loading"}
                >
                  Simpan
                </LoadingButton>
              </div>
            </>
          )}
        </div>
      </FormProvider>
    </>
  );
};

export default ImpactEdit;
