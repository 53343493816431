import { useMutation, useQuery } from '@tanstack/react-query';
import { BUDGET_ACCOUNT } from 'services';

export const useBudgetAccountList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal }) => {
      return BUDGET_ACCOUNT.getBudgetAccountsList(signal);
    },
    ...options,
  });
};

export const useBudgetAccountDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return BUDGET_ACCOUNT.getBudgetAccountById(id, signal);
    },
    ...options,
  });
};

export const useBudgetAccounts = () => {
  return useMutation((payload) => BUDGET_ACCOUNT.getBudgetAccounts(payload));
};

export const usePostBudgetAccount = () => {
  return useMutation((payload) => BUDGET_ACCOUNT.postBudgetAccount(payload));
};

export const useUpdateBudgetAccount = () => {
  return useMutation((payload) => BUDGET_ACCOUNT.updateBudgetAccount(payload));
};

export const useDeleteBudgetAccount = () => {
  return useMutation((id) => BUDGET_ACCOUNT.deleteBudgetAccount(id));
};
