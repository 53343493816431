import React, { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { notification } from "antd";

const ThematicProgrammesDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadDetailDataTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataTP, setDataTP] = useState();
  const loadDetailDataTP = () => {
    // setLoading(true);
    axios
      .get(`${MASTER_DATA_URL}/ThematicProgrammes/${params.id}`)
      .then((res) => {
        let tp = res.data?.data;
        setDataTP(tp);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const links = [
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      path: "/thematic-programmes",
      label: "Thematic Programmes",
    },
    {
      label: "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="space-y-1">
              <Label>Thematic Programmes</Label>
              <p className="text-sm text-gray-800">{dataTP?.nama}</p>
            </div>
            <div className="space-y-1">
              <Label>Deskripsi</Label>
              <p className="text-sm text-gray-800">{dataTP?.deskripsi}</p>
            </div>
            <div className="space-y-2">
              <Label>Daftar Sub Theme</Label>
              <div className="overflow-x-auto overflow-y-hidden">
                <table className="table w-full text-gray-600">
                  <thead className="border-b">
                    <tr>
                      <th className="bg-primary-50">No</th>
                      <th className="bg-primary-50">Sub Theme</th>
                      <th className="bg-primary-50">Deskripsi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTP?.subTheme &&
                      dataTP.subTheme.map((subTheme, index) => (
                        <tr className="border-b">
                          <td>{index + 1}</td>
                          <td>{subTheme?.name}</td>
                          <td>{subTheme?.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ThematicProgrammesDetail;
