import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const InformationForm = () => {
  const { register, control, watch } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Investment Criteria</Label>
        <InputForm className="w-full" name="impact" />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default InformationForm;
