import React, { useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { FiChevronDown, FiChevronUp, FiTrash } from "react-icons/fi";
import { map } from "lodash";

import { accountType } from "constants";
import { Button, CardBody, CardHeader, Label, TitleText } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { ConfirmationModal } from "components/Modal";

import SubOutputIndicators from "./SubOutputIndicators";
import classNames from "classnames";

const SubOutputForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();

  const [delSubOutputIndex, setDelSubOutputIndex] = useState(null);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "subOutputs",
  });

  const fieldsError = useMemo(() => {
    return errors?.subOutputs ?? [];
  }, [errors?.subOutputs]);

  return (
    <>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>Sub Output</div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="space-y-6">
          {map(fields, (subOutput, subOutputIndex) => {
            const isSubOutputHasError = fieldsError[subOutputIndex];

            const textColor = !isSubOutputHasError
              ? "text-primary-700"
              : "text-red-700";

            return (
              <div className="space-y-5" key={subOutput.id}>
                <>
                  <div
                    className={classNames(
                      "flex items-center justify-between bg-sub-green-100 p-5 rounded-lg",
                      {
                        "bg-red-200 border border-red-600": isSubOutputHasError,
                      }
                    )}
                  >
                    <TitleText className={`${textColor}`}>
                      Sub Output {subOutputIndex + 1}
                    </TitleText>

                    <div className="flex">
                      <Button
                        theme="link"
                        className="px-2 disabled:bg-inherit disabled:opacity-40 disabled:cursor-not-allowed"
                        onClick={() => setDelSubOutputIndex(subOutputIndex)}
                        disabled={fields.length === 1}
                      >
                        <FiTrash className={`${textColor} text-lg`} />
                      </Button>

                      <Button
                        theme="link"
                        className="px-2 disabled:bg-[unset] disabled:opacity-30"
                        onClick={() =>
                          update(subOutputIndex, {
                            ...subOutput,
                            open: !subOutput?.open,
                          })
                        }
                        disabled={isSubOutputHasError}
                      >
                        {subOutput?.open ? (
                          <FiChevronUp className={`${textColor} text-xl`} />
                        ) : (
                          <FiChevronDown className={`${textColor} text-xl`} />
                        )}
                      </Button>
                    </div>
                  </div>
                  <div
                    className={classNames("space-y-5 px-5", {
                      hidden: !subOutput?.open && !isSubOutputHasError,
                    })}
                  >
                    <div className="flex space-x-4">
                      <div style={{ display: "none" }}>
                        <InputForm
                          className="w-full"
                          name={`subOutputs.${subOutputIndex}.id`}
                        />
                      </div>

                      <div className="space-y-2 flex-[1] !ml-0">
                        <Label required>Format Penomoran</Label>
                        <InputForm
                          className="w-full"
                          {...register(
                            `subOutputs.${subOutputIndex}.numberingFormat`,
                            {
                              validate: (value) => {
                                return (
                                  !!value ||
                                  (value ?? "").trim() !== "" ||
                                  "Format Penomoran Wajib Diisi"
                                );
                              },
                            }
                          )}
                        />
                      </div>
                      <div className="space-y-2 flex-[4]">
                        <Label required>Sub Output</Label>
                        <InputForm
                          className="w-full"
                          {...register(`subOutputs.${subOutputIndex}.name`, {
                            validate: (value) => {
                              return (
                                !!value ||
                                (value ?? "").trim() !== "" ||
                                "Sub Output Wajib Diisi"
                              );
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="space-y-2 flex-1">
                      <Label>Deskripsi</Label>
                      <InputForm
                        type="textarea"
                        className="w-full"
                        name={`subOutputs.${subOutputIndex}.description`}
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="space-y-2 flex-1">
                        <Label required>Kode COA</Label>
                        <InputForm
                          className="w-full"
                          {...register(`subOutputs.${subOutputIndex}.coaCode`, {
                            validate: (value) => {
                              return (
                                !!value ||
                                (value ?? "").trim() !== "" ||
                                "Sub Output Wajib Diisi"
                              );
                            },
                          })}
                        />
                      </div>
                      <div className="space-y-2 flex-1">
                        <Label required>Tipe COA</Label>
                        <CustomSelectForm
                          name={`subOutputs.${subOutputIndex}.accountType`}
                          placeholder="Pilih Tipe COA"
                          options={accountType}
                          validation={{
                            required: {
                              value: true,
                              message: "Tipe COA Wajib Diisi",
                            },
                            validate: ({ value }) =>
                              !!value || "Tipe COA Wajib Diisi",
                          }}
                        />
                      </div>
                    </div>
                    <div className="border rounded-lg">
                      <div className="border-b px-6 py-4">
                        <TitleText className={""}>Indicator</TitleText>
                      </div>
                      <SubOutputIndicators subOutputIndex={subOutputIndex} />
                    </div>
                  </div>
                </>
              </div>
            );
          })}
        </div>

        <div className="py-6 text-right">
          <Button
            className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
            startIcon={<AiOutlinePlus className="text-white" />}
            onClick={() =>
              append({
                open: false,
                name: "",
                numberingFormat: "",
                description: "",
              })
            }
          >
            Tambah Sub Output
          </Button>
        </div>
      </CardBody>

      <ConfirmationModal
        open={delSubOutputIndex !== null}
        onClose={() => setDelSubOutputIndex(null)}
        onSubmit={() => {
          remove(delSubOutputIndex);
          setDelSubOutputIndex(null);
        }}
      />
    </>
  );
};

export default SubOutputForm;
