import React, { useCallback, useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { RiSave3Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router";

import { accountType } from "constants";
import { Breadcrumbs, Button, Card, CardBody, CardHeader } from "components";
import LoadingButton from "components/LoadingButton";

import {
  useBudgetAccountDetail,
  useUpdateBudgetAccount,
} from "hooks/budgetAccount";
import BudgetActivityForm from "./Form";

const breadcrumbs = [
  {
    path: "/master-data",
    label: "Master Data",
  },
  {
    path: "/master-data/budget-account",
    label: "Mata Anggaran",
  },
  {
    label: "Detail Mata Anggaran",
  },
];

const BudgetAccountEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useBudgetAccountDetail([
    `budget-account/${id}`,
    id,
  ]);

  const form = useForm({
    defaultValues: {},
  });

  const updateBudgetAccount = useUpdateBudgetAccount();

  const handleFormCancel = () => {
    navigate(-1);
  };

  const handleFormSubmit = useCallback(
    async (data) => {
      if (isEmpty(data)) {
        return;
      }

      try {
        await updateBudgetAccount.mutateAsync({
          id,
          code: data.code,
          nama: data.nama,
          deskripsi: data.deskripsi,
          idDetailKegiatan: data.idDetailKegiatan.value,
          accountType: data.accountType.value,
        });

        enqueueSnackbar({
          message: "Mata Anggaran berhasil diperbarui",
          variant: "success",
        });

        setTimeout(() => {
          navigate("/master-data/budget-account");
        }, 300);
      } catch (error) {
        enqueueSnackbar({
          message: typeof error === "string" ? error : error?.message,
          variant: "error",
        });
      }
    },
    [id, updateBudgetAccount, navigate]
  );

  useEffect(() => {
    if (!isLoading && data?.data) {
      const budgetAccount = data.data;

      form.setValue("code", budgetAccount.code);
      form.setValue("nama", budgetAccount.nama);
      form.setValue("deskripsi", budgetAccount.deskripsi);

      form.setValue("kegiatanUtama", {
        value: budgetAccount.idKegiatanUtama,
        label: budgetAccount.kegiatanUtama,
      });

      form.setValue(
        "accountType",
        accountType.find(({ value }) => value === budgetAccount.accountType)
      );
    }
  }, [data?.data, form, isLoading]);

  return (
    <div className="pt-12 space-y-6">
      <Breadcrumbs items={breadcrumbs} />

      <Card>
        <CardHeader>Ubah Mata Anggaran</CardHeader>
        <CardBody>
          <FormProvider {...form}>
            <BudgetActivityForm mode="edit" detail={data?.data ?? {}}>
              <div className="flex-1 flex justify-between items-center space-x-4">
                <Button
                  type="button"
                  className="w-1/2 lg:w-60 text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                  onClick={handleFormCancel}
                >
                  Batal
                </Button>
                <LoadingButton
                  type="button"
                  className="w-1/2 lg:w-60 button-primary with-loading"
                  endIcon={<RiSave3Line className="text-white" size={20} />}
                  onClick={form.handleSubmit(handleFormSubmit)}
                  isLoading={updateBudgetAccount.status === "loading"}
                >
                  Simpan
                </LoadingButton>
              </div>
            </BudgetActivityForm>
          </FormProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default BudgetAccountEdit;
