import React, { useEffect, useState } from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const InstitutionTypeForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Jenis Lembaga</Label>
        <InputForm className="w-full" name="institution_type" />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Tujuan Utama</Label>
        <CustomSelectForm
          name={`main_purpose`}
          placeholder=" "
          options={[
            { value: 1, label: "Satu" },
            { value: 2, label: "Dua" },
            { value: 3, label: "Tiga" },
          ]}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default InstitutionTypeForm;
