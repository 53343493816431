import React from "react";
import { Button, Label, TitleText } from "components";
import { InputForm } from "components/HookForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { map } from "lodash";

const Form = () => {
  const { register, control, watch, getValues } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: "ratio",
  });
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Jenis Rasio</Label>
        <InputForm
          className="w-full"
          name="ratio_type"
          defaultValue="#0"
          disabled
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Tujuan Utama Lembaga</Label>
        <InputForm
          className="w-full"
          name="main_purpose"
          defaultValue="#0"
          disabled
        />
      </div>
      <div className="space-y-5">
        <TitleText className="text-primary-700 font-bold">Indicator</TitleText>
        <table className="table w-full text-gray-600">
          <thead className="border-b">
            <tr>
              <th className="bg-primary-50">No</th>
              <th className="bg-primary-50">Nama Rasio</th>
              <th className="bg-primary-50">Nilai Minimum (Treshold)</th>
              <th className="bg-primary-50"></th>
            </tr>
          </thead>
          <tbody>
            {map(fields, (field, key) => (
              <tr className="border-b" key={field.id}>
                <td className="w-[0px]">{key + 1}</td>
                <td>{field.ratio_name}</td>
                <td>
                  <InputForm
                    className="w-full"
                    name={`ratio.${key}.min_value`}
                  />
                </td>
                <td className="w-[0px]">
                  <Button
                    type="button"
                    onClick={() => {}}
                    className="text-white border border-error-500 bg-error-500 hover:bg-error-700 hover:border-error-700 h-fit min-h-fit p-3"
                  >
                    <BiTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Standar Nilai Minimum</Label>
        <InputForm className="w-full" name="standard_min_value" disabled />
      </div>
    </div>
  );
};

export default Form;
