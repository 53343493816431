import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router";
import { findIndex } from "lodash";
import axios from "axios";

import { MASTER_DATA_URL } from "services/config";
import { accountType } from "constants";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ParameterForm,
} from "components";
import LoadingButton from "components/LoadingButton";
import { FormModal } from "components/Modal";
import { RiSave3Line } from "react-icons/ri";

import IndicatorForm from "./indicatorForm";
import IndicatorItem from "./indicatorItem";
import InformationForm from "./informationForm";

const OutcomeForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      loadDetailDataOutcome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataOutcome = () => {
    axios
      .get(`${MASTER_DATA_URL}/Outcome/${params.id}`)
      .then((res) => {
        let outcome = res.data?.data;

        const selectedAccountType = accountType.find(
          (at) => at.value === outcome?.accountType
        );

        methods.setValue("numberingFormat", outcome?.numberingFormat);
        methods.setValue("nama", outcome?.nama);
        methods.setValue("deskripsi", outcome?.deskripsi);
        methods.setValue("coaCode", outcome?.coaCode);
        methods.setValue("accountType", selectedAccountType);
        methods.setValue("indicator", outcome?.indicator);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const onFinishForm = () => {
    let payload = methods.getValues();
    payload.id = params.id;
    payload.accountType = payload.accountType.value;

    setLoading(true);

    if (params.id) {
      axios
        .put(`${MASTER_DATA_URL}/Outcome`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil mengubah data Outcome!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(`${MASTER_DATA_URL}/Outcome`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil menambah data Outcome!",
          });
          navigate(-1);
        })
        .catch((err) => {
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const methods = useForm({
    defaultValues: {
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      coaCode: "",
      accountType: accountType[0],
      indicator: [],
    },
  });

  const {
    fields: fieldsIndicator,
    append: appendIndicator,
    remove: removeIndicator,
    update: updateIndicator,
  } = useFieldArray({
    control: methods.control,
    name: "indicator",
  });

  const indicatorMethods = useForm({
    defaultValues: {
      id: null,
      numberingFormat: "",
      nama: "",
      deskripsi: "",
      parameter: [],
    },
  });

  const [indicatorModal, setIndicatorModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (!indicatorModal) {
      indicatorMethods.reset({
        numberingFormat: "",
        nama: "",
        deskripsi: "",
        id: null,
        parameter: [],
      });
    }

    !indicatorModal && setIsEdit(false);

    return;
  }, [indicatorMethods, indicatorModal]);

  const links = [
    {
      path: "/theory-of-change",
      label: "Theory of Change",
    },
    {
      path: "/theory-of-change/outcome",
      label: "Outcome",
    },
    {
      label: params.id ? "Edit Outcome" : "Tambah Outcome",
    },
  ];

  const onCreateIndicator = (payload) => {
    setIndicatorModal(false);
    if (isEdit) {
      const index = findIndex(
        fieldsIndicator,
        (field) => field.id === payload.id
      );

      updateIndicator(index, payload);
    } else {
      appendIndicator(payload);
    }
    setIndicatorModal(false);
  };

  const onEditIndicator = (data) => {
    setIsEdit(true);
    indicatorMethods.reset({
      ...data,
    });
    setIndicatorModal(true);
  };

  const onDeleteIndicator = (data) => {
    const index = findIndex(fieldsIndicator, (field) => field.id === data.id);
    removeIndicator(index);
  };

  return (
    <div>
      <FormModal
        title={isEdit ? "Edit Indicator" : "Tambah Indicator"}
        open={indicatorModal}
        onClose={setIndicatorModal}
        onSubmit={() => indicatorMethods.handleSubmit(onCreateIndicator)()}
      >
        <FormProvider {...indicatorMethods}>
          <div className="space-y-5">
            <IndicatorForm open={indicatorModal} editMode={isEdit} />
            <ParameterForm open={indicatorModal} />
          </div>
        </FormProvider>
      </FormModal>

      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>
              <div>{params.id ? "Edit Outcome" : "Tambah Outcome"}</div>
            </CardHeader>
            <CardBody>
              <InformationForm editMode={!!params.id} />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="flex items-center">Indicator</div>
            </CardHeader>
            <CardBody>
              <IndicatorItem
                fields={fieldsIndicator}
                onEdit={onEditIndicator}
                onDelete={onDeleteIndicator}
              />
            </CardBody>
            <CardFooter>
              <div className="flex items-center justify-end">
                <Button
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                  startIcon={<AiOutlinePlus />}
                  onClick={() => setIndicatorModal(true)}
                >
                  Tambah Indicator
                </Button>
              </div>
            </CardFooter>
          </Card>
          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
            >
              Batal
            </Button>
            <LoadingButton
              type="button"
              className="button-primary with-loading w-60"
              endIcon={<RiSave3Line className="text-white" size={20} />}
              onClick={() => methods.handleSubmit(onFinishForm)()}
              isLoading={isLoading}
            >
              Simpan
            </LoadingButton>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default OutcomeForm;
