import React from "react";
import classNames from "classnames";

const defaultClassName =
  "py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 focus:border-green-500 rounded-md focus-visible:!outline-none";
const errorClassName = "!border-error-500";
const Input = React.forwardRef(({ isError = false, ...props }, ref) => {
  if (props.type === "textarea") {
    return (
      <textarea
        ref={ref}
        {...props}
        className={classNames(
          ...defaultClassName.split(" "),
          ...props?.className.split(" "),
          { [errorClassName]: isError }
        )}
        style={{ boxShadow: "none" }}
      />
    );
  }
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        ...defaultClassName.split(" "),
        ...props?.className.split(" "),
        { [errorClassName]: isError }
      )}
      style={{ boxShadow: "none" }}
    />
  );
});

export default Input;
