import SigninCallback from "pages/SigninCallback";
import Unauthenticated from "pages/Unauthenticated";

const routesGuest = [
  {
    routeType: "guest",
    path: "/signin-oidc",
    element: SigninCallback,
    exact: true,
  },
  {
    routeType: "guest",
    path: "/unauthenticated",
    element: Unauthenticated,
    exact: true,
  },
];

export default routesGuest;
