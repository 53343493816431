import { useFormContext } from "react-hook-form";

import { accountType } from "constants/options";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { getAccountTypeValue } from "helpers";
import { Label } from "components";

const MainActivityForm = ({ children, mode = "create", detail }) => {
  const { register } = useFormContext();

  const isDetailMode = !["create", "edit"].includes(mode);

  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required={!isDetailMode}>Kode</Label>
        {!isDetailMode ? (
          <InputForm
            className="w-full"
            {...register("code", {
              validate: (value) => {
                return (value ?? "").trim() !== "" || "Kode Wajib Diisi";
              },
            })}
          />
        ) : (
          <p>{detail.code}</p>
        )}
      </div>
      <div className="space-y-2 flex-1">
        <Label required={!isDetailMode}>Kegiatan Utama</Label>
        {!isDetailMode ? (
          <InputForm
            className="w-full"
            {...register("kegiatanUtama", {
              validate: (value) => {
                return (
                  (value ?? "").trim() !== "" || "Kegiatan Utama Wajib Diisi"
                );
              },
            })}
          />
        ) : (
          <p>{detail.kegiatanUtama}</p>
        )}
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Tipe COA</Label>
        {!isDetailMode ? (
          <CustomSelectForm
            placeholder="Select Account Type"
            options={accountType}
            {...register("accountType", {
              required: {
                value: true,
                message: "Tipe COA Wajib Diisi",
              },
              validate: ({ value }) => !!value || "Tipe COA Wajib Diisi",
            })}
          />
        ) : (
          <p>{getAccountTypeValue(accountType, detail.accountType)}</p>
        )}
      </div>
      {isDetailMode && (
        <div className="flex justify-between">
          <div className="w-1/2 space-y-1">
            <Label>Level</Label>
            <p className="text-sm text-gray-800">{detail?.level}</p>
          </div>
          <div className="w-1/2 space-y-1">
            <Label>Full Code</Label>
            <p className="text-sm text-gray-800">{detail?.coaFullCode ?? ""}</p>
          </div>
        </div>
      )}
      <div className="space-y-2 flex-1">
        <Label>Deksripsi</Label>
        {!isDetailMode ? (
          <InputForm
            type="textarea"
            className="w-full"
            name="deskripsi"
            rows="5"
          />
        ) : (
          <p>{detail.deskripsi}</p>
        )}
      </div>

      {isDetailMode && (
        <div className="space-y-2 flex-1">
          <Label>Daftar Detail Kegiatan</Label>
          <p>-</p>
        </div>
      )}

      {children}
    </div>
  );
};

export default MainActivityForm;
