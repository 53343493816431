export const accountType = [
  {
    label: 'Aset',
    value: 1,
  },
  {
    label: 'Liabilitas',
    value: 2,
  },
  {
    label: 'Equity',
    value: 3,
  },
  {
    label: 'Income',
    value: 4,
  },
  {
    label: 'Beban/Biaya',
    value: 5,
  },
];
