export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    'Something Went Wrong';
  return message;
};

export function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getAccountTypeValue(accountTypes, selectedAccType) {
  const accountType = accountTypes.filter(
    (item) => item.value === selectedAccType
  );

  return accountType.length > 0 ? accountType[0].label : '-';
}
