import React, { useEffect, useState } from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFieldArray, useFormContext } from "react-hook-form";

const SubIndicativeActivityForm = () => {
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Sub Indicative Activity</Label>
        <InputForm className="w-full" name="name" />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default SubIndicativeActivityForm;
