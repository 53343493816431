import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { debounce } from "lodash";
import { RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

import { accountType, detailActivityLinks } from "constants";
import { BasicList } from "components/ListPage";
import { ConfirmationModal } from "components/Modal";
import { getAccountTypeValue } from "helpers";
import {
  useDetailActivity,
  useDeleteDetailActivity,
} from "hooks/detailActivity";

const DetailActivity = () => {
  const navigate = useNavigate();

  const datatatableDetailActivity = useDetailActivity();
  const deleteDetailActivity = useDeleteDetailActivity();
  const { enqueueSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [isModalConfirmationOpen, setIsModalConfirmationOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const paginator = useMemo(() => {
    return {
      page: page,
      limit: limit,
      total: total,
      previous_pages: total > limit,
      next_pages: total > limit,
    };
  }, [page, limit, total]);

  // constants
  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "code",
        title: "Kode",
        dataIndex: "code",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "detail",
        title: "Detail Kegiatan",
        dataIndex: "detail",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px] truncate">
            {value}
          </div>
        ),
      },
      {
        id: "kegiatanUtama",
        title: "Kegiatan Utama",
        dataIndex: "kegiatanUtama",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px] truncate">
            {value}
          </div>
        ),
      },
      {
        id: "deskripsi",
        title: "Deskripsi",
        dataIndex: "deskripsi",
        width: 200,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "accountType",
        title: "Account Type",
        dataIndex: "accountType",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {getAccountTypeValue(accountType, value)}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <RiEyeLine
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                navigate(`/master-data/detail-activity/${data.id}`);
              }}
            />
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => {
                navigate(`/master-data/detail-activity/${data.id}/edit`);
              }}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onClickDelete(data)}
            />
          </div>
        ),
      },
    ];
  }, [navigate, paginator?.page, paginator?.limit]);

  const getDatatableDetailActivity = useCallback(() => {
    datatatableDetailActivity.mutate(
      {
        pageNumber: paginator?.page,
        pageSize: paginator?.limit,
        generalSearch: keyword,
      },
      {
        onSuccess: (result) => {
          setTotal(result.data.totalCount);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }, [datatatableDetailActivity, keyword, paginator?.page, paginator?.limit]);

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    200
  );

  const onDelete = useCallback(() => {
    deleteDetailActivity.mutate(selectedRow.id, {
      onSuccess: () => {
        setIsModalConfirmationOpen(false);
        getDatatableDetailActivity();

        enqueueSnackbar({
          message: "Detail Kegiatan berhasil dihapus",
          variant: "success",
        });
      },
      onError: (error) => {
        enqueueSnackbar({
          message: typeof error === "string" ? error : error?.message,
          variant: "error",
        });
      },
    });
  }, [
    deleteDetailActivity,
    enqueueSnackbar,
    getDatatableDetailActivity,
    selectedRow,
  ]);

  const onClickCreate = () => {
    navigate("/master-data/detail-activity/create");
  };

  const onClickDelete = (data) => {
    setIsModalConfirmationOpen(true);
    setSelectedRow(data);
  };

  // cycle
  useEffect(() => {
    getDatatableDetailActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator?.page, paginator?.limit, keyword]);

  return (
    <div>
      <ConfirmationModal
        open={isModalConfirmationOpen}
        onClose={setIsModalConfirmationOpen}
        onSubmit={onDelete}
      />

      <BasicList
        links={detailActivityLinks}
        title={"Daftar Detail Kegiatan"}
        columns={tableColumns}
        dataSource={datatatableDetailActivity?.data?.data?.data ?? []}
        pagination={paginator}
        isLoading={datatatableDetailActivity.isLoading}
        onCreate={onClickCreate}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        onSearch={onSearch}
      />
    </div>
  );
};

export default DetailActivity;
