import React, { forwardRef } from "react";
import { Input } from "components";
import { useFormContext, Controller } from "react-hook-form";

const InputForm = forwardRef(({ name, ...props }, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => (
        <div className="w-full">
          <Input
            {...props}
            ref={ref}
            value={value}
            onChange={onChange}
            isError={invalid}
          />
          {invalid && (
            <div className="text-xs text-error-600 mt-2">{error?.message}</div>
          )}
        </div>
      )}
    />
  );
});

export default InputForm;
