import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { notification } from "antd";
import axios from "axios";

import { accountType } from "constants";
import { MASTER_DATA_URL } from "services/config";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Spinner,
} from "components";

import InformationForm from "./informationForm";
import SubOutputForm from "./subOutputForm";

const OutputForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setLoading] = useState(!!params.id);

  useEffect(() => {
    if (params.id) {
      loadDetailDataOutcome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataOutcome = () => {
    setLoading(true);

    axios
      .get(`${MASTER_DATA_URL}/Output/${params.id}`)
      .then((res) => {
        let output = res.data?.data;

        methods.setValue("numberingFormat", output?.numberingFormat);
        methods.setValue("nama", output?.nama);
        methods.setValue("deskripsi", output?.deskripsi);
        methods.setValue("coaCode", output?.coaCode);
        methods.setValue(
          "accountType",
          accountType.find((at) => at.value === output?.accountType)
        );
        methods.setValue("indicator", output?.indicator);

        methods.setValue(
          "subOutputs",
          (output?.subOutputs ?? []).map((subOutput) => ({
            ...subOutput,
            accountType: accountType.find(
              (at) => at.value === subOutput?.accountType
            ),
          }))
        );
      })
      .catch((err) => {
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishForm = () => {
    let payload = methods.getValues();
    payload.id = params.id;
    payload.accountType = payload.accountType.value;

    let arr = [];
    payload.subOutputs?.forEach((so) => {
      delete so.open;

      so.accountType = so.accountType?.value;
      arr.push(so);
    });

    payload.subOutputs = arr;

    if (params.id) {
      axios
        .put(`${MASTER_DATA_URL}/Output`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil mengubah data Output!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    } else {
      axios
        .post(`${MASTER_DATA_URL}/Output`, payload)
        .then((res) => {
          notification.success({
            message: "Berhasil",
            description: "Berhasil menambah data Output!",
          });
          navigate(-1);
        })
        .catch((err) => {
          // setLoading(false);
          notification.error({
            message: "Kesalahan",
            description: err.message,
          });
        });
    }
  };

  const methods = useForm({
    defaultValues: {
      numberingFormat: "",
      nama: "",
      description: "",
      coaCode: "",
      accountType: accountType[0],
      subOutputs: [
        {
          open: true,
          name: "",
          numberingFormat: "",
          description: "",
          coaCode: "",
          accountType: null,
          indicators: [],
        },
      ],
    },
  });

  const links = [
    {
      label: "Theory of Change",
    },
    {
      path: "/theory-of-change/output",
      label: "Output",
    },
    {
      label: params.id ? "Edit Output" : "Tambah Output",
    },
  ];

  if (isLoading) {
    return (
      <div className="h-screen w-full relative pt-8">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>
              <div>{params.id ? "Edit Output" : "Tambah Output"}</div>
            </CardHeader>
            <CardBody>
              <InformationForm />
            </CardBody>
          </Card>

          <Card>
            <SubOutputForm />
          </Card>

          <div className="block md:flex items-center justify-between border border-gray-200 rounded-lg  bg-white mt-4 mb-10  px-6 py-5 space-x-3 lg:space-x-0">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-1/2 lg:w-60"
              onClick={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-1/2 lg:w-60"
              onClick={methods.handleSubmit(onFinishForm)}
            >
              Simpan
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default OutputForm;
