import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";

const SearchInput = ({ placeholder = "Cari", onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const borderColor = isFocused ? "border-green-500" : "border-gray-200";

  return (
    <div
      className={`flex flex-1 items-center rounded-md  px-4 space-x-3 ${borderColor} border-solid border-[1px]`}
    >
      <BiSearch className="text-gray-400" size={20} />
      <input
        className="bg-white border-none text-gray-400 w-full text-sm p-2 outline-none"
        style={{ boxShadow: "none" }}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
};

export default SearchInput;
