import React, { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
  TitleText,
} from "components";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate, useParams } from "react-router";
import { MASTER_DATA_URL } from "services/config";
import axios from "axios";
import { notification } from "antd";

const OutcomeDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      // setIsEdit(true)
      loadDetailDataOutcome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataOutcome, setDataOutcome] = useState();
  const loadDetailDataOutcome = () => {
    // setLoading(true);
    axios
      .get(`${MASTER_DATA_URL}/Outcome/${params.id}`)
      .then((res) => {
        // setLoading(false);
        setDataOutcome(res.data.data);
      })
      .catch((err) => {
        // setLoading(false);
        notification.error({
          message: "Kesalahan",
          description: err.message,
        });
      });
  };

  const links = [
    {
      path: "/theory-of-change",
      label: "Theory of Change",
    },
    {
      path: "/theory-of-change/outcome",
      label: "Outcome",
    },
    {
      label: "Detail",
    },
  ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <span
        className="flex items-center space-x-2 cursor-pointer text-gray-600"
        onClick={() => navigate(-1)}
      >
        <CgArrowLeft className="text-lg" />
        <p className="font-bold text-sm">Back to Outcome</p>
      </span>
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>
        <CardBody>
          <div className="space-y-5">
            <div className="flex items-center justify-between">
              <div className="w-1/2 space-y-1">
                <Label>Outcome</Label>
                <p className="text-sm text-gray-800">{dataOutcome?.nama}</p>
              </div>
              <div className="w-1/2 space-y-1">
                <Label>Format Penomoran</Label>
                <p className="text-sm text-gray-800">
                  {dataOutcome?.numberingFormat}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-1/2 space-y-1">
                <Label>Level</Label>
                <p className="text-sm text-gray-800">{dataOutcome?.level}</p>
              </div>
              <div className="w-1/2 space-y-1">
                <Label>Full Code</Label>
                <p className="text-sm text-gray-800">
                  {dataOutcome?.coaFullCode ?? ""}
                </p>
              </div>
            </div>
            <div className="space-y-1">
              <Label>Deskripsi</Label>
              <p className="text-sm text-gray-800">{dataOutcome?.deskripsi}</p>
            </div>
            <div className="space-y-5">
              <TitleText className="text-primary-700 font-bold">
                Indicator
              </TitleText>

              <div className="overflow-x-auto overflow-y-hidden">
                <table className="table w-full text-gray-600">
                  <thead className="border-b">
                    <tr>
                      <th className="bg-primary-50">No</th>
                      <th className="bg-primary-50">Indicator</th>
                      <th className="bg-primary-50">Deskripsi</th>
                      <th className="bg-primary-50">Parameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataOutcome?.indicator &&
                      dataOutcome.indicator.map((indicator) => (
                        <>
                          <tr className="border-b">
                            <td>{indicator.numberingFormat}</td>
                            <td>{indicator.nama}</td>
                            <td>{indicator.deskripsi}</td>
                            <td>
                              {indicator.parameter &&
                                indicator.parameter.map((param) => (
                                  <div
                                    style={{ borderBottom: "solid 1px #000" }}
                                  >
                                    {param.nama} {`(${param.satuan})`}
                                  </div>
                                ))}
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default OutcomeDetail;
