export const mainActivityLinks = [
  {
    path: '/master-data',
    label: 'Master Data',
  },
  {
    label: 'Kegiatan Utama',
  },
];

export const detailActivityLinks = [
  {
    path: '/master-data',
    label: 'Master Data',
  },
  {
    label: 'Detail Kegiatan',
  },
];

export const budgetAccountLinks = [
  {
    path: '/master-data',
    label: 'Master Data',
  },
  {
    label: 'Mata Anggaran',
  },
];
