import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import { CgSpinner } from "react-icons/cg";

const LoadingButton = ({
  theme = "",
  outline = false,
  size = "",
  type = "Loadingbutton",
  className = "",
  onClick,
  disabled = false,
  startIcon = null,
  endIcon = null,
  children,
  iconOnly = false,
  isLoading = false,
}) => {
  const themeHandler = (val) => {
    switch (val) {
      case "primary":
        return `btn-primary`;
      case "secondary":
        return `btn-secondary`;
      case "accent":
        return `btn-accent`;
      case "info":
        return `btn-info`;
      case "success":
        return `btn-success`;
      case "warning":
        return `btn-warning`;
      case "error":
        return `btn-error`;
      case "active":
        return `btn-active`;
      case "ghost":
        return `btn-ghost`;
      case "link":
        return `btn-link`;
      default:
        return "";
    }
  };

  const sizeHandler = (val) => {
    switch (val) {
      case "xl":
        return `btn-xl`;
      case "lg":
        return `btn-lg`;
      case "md":
        return `btn-md`;
      case "sm":
        return `btn-sm`;
      case "xs":
        return `btn-xs`;
      default:
        return "";
    }
  };

  return (
    <motion.button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={classNames(
        [
          "btn",
          "capitalize",
          "flex",
          "items-center",
          sizeHandler(size),
          themeHandler(theme),
        ],
        { "btn-outline": outline },
        { "gap-2": (startIcon || isLoading || endIcon) && !iconOnly },
        ...className.split(" ")
      )}
    >
      {startIcon}
      {children}
      {isLoading ? (
        <div>
          <CgSpinner
            className={classNames([
              "animate-spin",
              "text-inherit",
              "bg-transparent",
            ])}
          />
        </div>
      ) : endIcon ? (
        endIcon
      ) : null}
    </motion.button>
  );
};

export default LoadingButton;
