import { Button, Label, TitleText } from "components";
import { InputForm } from "components/HookForm";
import { find, map } from "lodash";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";

const ParameterForm = ({ open }) => {
  const { register } = useFormContext();

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parameter",
  });

  useEffect(() => {
    if (!open) {
      remove();
    }
  }, [open]);

  return (
    <div className="space-y-2">
      <TitleText className="text-primary-700 font-bold">Parameters</TitleText>
      <div className="space-y-2">
        {map(fields, (parameter, key) => (
          <div key={parameter.id} className="flex justify-between space-x-4">
            <div className="flex flex-1 space-x-4">
              <div className="space-y-2 flex-[4]">
                <Label required>Parameter {key + 1}</Label>
                <InputForm
                  className="w-full"
                  name={`parameter.${key}.nama`}
                  {...register(`parameter.${key}.nama`, {
                    validate: (value) => {
                      return value.trim() !== "" || "Parameter Wajib Diisi";
                    },
                  })}
                />
              </div>
              <div className="space-y-2 flex-[3]">
                <Label required>Satuan</Label>
                <InputForm
                  className="w-full"
                  name={`parameter.${key}.satuan`}
                  {...register(`parameter.${key}.satuan`, {
                    validate: (value) => {
                      return value.trim() !== "" || "Satuan Wajib Diisi";
                    },
                  })}
                />
              </div>
            </div>
            <div className="space-y-2 flex-none">
              <Label>&nbsp;</Label>
              <Button
                type="button"
                onClick={() => {
                  remove(key);
                }}
                className="text-white border border-error-500 bg-error-500 hover:bg-error-700 hover:border-error-700 h-fit min-h-fit p-3"
              >
                <BiTrash />
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end border-t pt-3">
        <Button
          className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 h-fit min-h-fit py-2"
          startIcon={<AiOutlinePlus />}
          onClick={() => append({ nama: "", satuan: "" })}
        >
          <div>Tambah Parameter</div>
        </Button>
      </div>
    </div>
  );
};

export default ParameterForm;
