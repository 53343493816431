import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { BasicList } from "components/ListPage";
import { FormModal } from "components/Modal";

import CooperationAreaForm from "./Form";
import { CategoryBadge, TypeBadge } from "components";

const CooperationArea = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [typeFilter, setTypeFilter] = useState(undefined);
  const [categoryFilter, setCategoryFilter] = useState(undefined);

  const [formModal, setFormModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const links = [
    {
      path: "/master-data",
      label: "Master Data",
    },
    {
      label: "Bidang Kerjasama",
    },
  ];

  const methods = useForm({
    defaultValues: {
      cooperation_field: "",
      description: "",
    },
  });

  const result = useMemo(() => {
    return [
      {
        code: 12,
        cooperationArea: "Bidang Kerjasama A",
        category: {
          id: 1,
          name: "Domestic",
        },
        type: {
          id: 1,
          name: "Public",
        },
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 142,
        cooperationArea: "Bidang Kerjasama B",
        category: {
          id: 1,
          name: "Domestic",
        },
        type: {
          id: 2,
          name: "Private",
        },
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 112,
        cooperationArea: "Bidang Kerjasama C",
        category: {
          id: 2,
          name: "International",
        },
        type: {
          id: 1,
          name: "Public",
        },
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 512,
        cooperationArea: "Bidang Kerjasama D",
        category: {
          id: 1,
          name: "Domestic",
        },
        type: {
          id: 1,
          name: "Public",
        },
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 162,
        cooperationArea: "Bidang Kerjasama E",
        category: {
          id: 2,
          name: "International",
        },
        type: {
          id: 2,
          name: "Private",
        },
        description: "Lorem ipsum Dolor Sit Amet",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const typeOptions = useMemo(() => {
    return [
      { value: 1, label: "Public" },
      { value: 2, label: "Private" },
    ];
  });

  const categoryOptions = useMemo(() => {
    return [
      { value: 1, label: "Domestic" },
      { value: 2, label: "International" },
    ];
  });

  const onChangeType = useCallback((obj) => setTypeFilter(obj.value), []);
  const onChangeCategory = useCallback(
    (obj) => setCategoryFilter(obj.value),
    []
  );

  const onCreate = () => {};
  const onEdit = (data) => {
    setFormModal(true);
    methods.reset(data);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "cooperationArea",
        title: "Bidang Kerjasama",
        dataIndex: "cooperationArea",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Deskripsi",
        dataIndex: "description",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => onEdit(data)}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => null}
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div>
      <FormModal
        title={"Tambah Bidang Kerjasama"}
        open={formModal}
        onClose={setFormModal}
        onSubmit={() => methods.handleSubmit(onCreate)()}
      >
        <FormProvider {...methods}>
          <CooperationAreaForm />
        </FormProvider>
      </FormModal>
      <BasicList
        links={links}
        title={"Daftar Bidang Kerjasama"}
        onCreate={() => setFormModal(true)}
        columns={tableColumns}
        dataSource={result}
        // isLoading={status === "loading"}
        // onChangePage={setNewPage}
        // onChangeRowsPerPage={setNewLimit}
        pagination={paginator}
      />
    </div>
  );
};

export default CooperationArea;
