import React from "react";
import { map } from "lodash";
import { BiPencil, BiTrash } from "react-icons/bi";

const IndicatorItem = ({ data, onEdit, onDelete }) => {
  return (
    <div
      className="border-b-2 border-gray-200 px-2 pb-4 flex space-x-3"
      key={data.id}
    >
      <div className="flex flex-1 items-center space-x-2">
        <div className="text-sm text-gray-800 px-2">
          {data?.numberingFormat}
        </div>
        <div className="flex flex-col flex-1 space-y-1">
          <div className="text-sm text-gray-800">{data?.nama}</div>
          <div className="text-xs text-gray-500">{data?.deskripsi}</div>
          <div className="flex items-center space-x-2 pt-1">
            {map(data?.parameter, (param, key) => (
              <div
                key={param.id ?? `SOI${data.id}P${key}`}
                className="text-[10px] rounded-full px-2 py-1 bg-blue-50 text-blue-700 font-medium"
              >
                {param?.nama}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-none items-center space-x-3 pr-2">
        <BiPencil
          className="flex-1 text-gray-600 cursor-pointer"
          onClick={onEdit}
        />
        <BiTrash
          className="flex-1 text-gray-600 cursor-pointer"
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

export default IndicatorItem;
