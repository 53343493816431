import React, { useMemo } from "react";

import { BasicList } from "components/ListPage";

const ActivityLog = () => {
  const links = [
    {
      path: "/user-management",
      label: "User Management",
    },
    {
      label: "Log Aktivitas",
    },
  ];

  const result = useMemo(() => {
    return [
      {
        name: "Nama User",
        activity: "Edit ....",
        date: "18-04-2023",
        location: "Bandung, Jawa Barat",
        ip: "125.164.23.91",
        device_id: "C184D2BD-A221-4F15-843F-E7DE0E789C32",
      },
      {
        name: "Nama User",
        activity: "Edit ....",
        date: "18-04-2023",
        location: "Bandung, Jawa Barat",
        ip: "125.164.23.91",
        device_id: "C184D2BD-A221-4F15-843F-E7DE0E789C32",
      },
      {
        name: "Nama User",
        activity: "Edit ....",
        date: "18-04-2023",
        location: "Bandung, Jawa Barat",
        ip: "125.164.23.91",
        device_id: "C184D2BD-A221-4F15-843F-E7DE0E789C32",
      },
      {
        name: "Nama User",
        activity: "Edit ....",
        date: "18-04-2023",
        location: "Bandung, Jawa Barat",
        ip: "125.164.23.91",
        device_id: "C184D2BD-A221-4F15-843F-E7DE0E789C32",
      },
      {
        name: "Nama User",
        activity: "Edit ....",
        date: "18-04-2023",
        location: "Bandung, Jawa Barat",
        ip: "125.164.23.91",
        device_id: "C184D2BD-A221-4F15-843F-E7DE0E789C32",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "name",
        title: "Nama User",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "activity",
        title: "Aktivitas",
        dataIndex: "activity",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "date",
        title: "Tanggal",
        dataIndex: "date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "location",
        title: "Lokasi",
        dataIndex: "location",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "ip",
        title: "IP",
        dataIndex: "ip",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "device_id",
        title: "Device ID",
        dataIndex: "device_id",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <BasicList
      links={links}
      title={"Daftar Log Aktivitas"}
      columns={tableColumns}
      dataSource={result}
      // isLoading={status === "loading"}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      pagination={paginator}
    />
  );
};

export default ActivityLog;
