import React from "react";

import ImpactNew from "./new";
import { useParams } from "react-router";
import ImpactEdit from "./edit";

const ImpactForm = () => {
  const { id } = useParams();
  return id ? <ImpactEdit id={id} /> : <ImpactNew />;
};

export default ImpactForm;
