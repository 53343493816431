import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const InformationForm = () => {
  const { register } = useFormContext();

  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Format Penomoran</Label>
        <InputForm
          className="w-full"
          {...register("numberingFormat", {
            validate: (value) => {
              return value.trim() !== "" || "Format Penomoran Wajib Diisi";
            },
          })}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Impact</Label>
        <InputForm
          className="w-full"
          {...register("nama", {
            validate: (value) => {
              return value.trim() !== "" || "Impact Wajib Diisi";
            },
          })}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="deskripsi" />
      </div>
    </div>
  );
};

export default InformationForm;
