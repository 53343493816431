import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components";
import { logIn } from "app/reducer/authSlice";

const Unauthenticated = () => {
  const authentication = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [redirectTimeout, setRedirectTimeout] = useState(5);

  const handleLogin = () => dispatch(logIn());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRedirectTimeout((prev) => prev - 1);
    }, 1000);

    if (redirectTimeout === 0) {
      clearInterval(intervalId);
      handleLogin();
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTimeout]);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <p className="text-4xl text-center font-bold mb-1">
        Anda belum login, silahkan login terlebih dahulu.
      </p>
      <p className="mb-6">
        Anda akan diarahkan secara otomatis ke halaman login dalam{" "}
        {redirectTimeout} detik.
      </p>

      <Button
        theme="link"
        className="text-green-500"
        onClick={handleLogin}
        disabled={authentication.isLoading}
      >
        Login Sekarang
      </Button>
    </div>
  );
};

export default Unauthenticated;
