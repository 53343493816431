import { useMutation, useQuery } from '@tanstack/react-query';
import { DETAIL_ACTIVITY } from 'services';

export const useDetailActivityList = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal }) => {
      return DETAIL_ACTIVITY.getDetailActivitiesList(signal);
    },
    ...options,
  });
};

export const useDetailActivityDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return DETAIL_ACTIVITY.getDetailActivityById(id, signal);
    },
    ...options,
  });
};

export const useDetailActivity = () => {
  return useMutation((payload) => DETAIL_ACTIVITY.getDetailActivities(payload));
};

export const usePostDetailActivity = () => {
  return useMutation((payload) => DETAIL_ACTIVITY.postDetailActivity(payload));
};

export const useUpdateDetailActivity = () => {
  return useMutation((payload) =>
    DETAIL_ACTIVITY.updateDetailActivity(payload)
  );
};

export const useDeleteDetailActivity = () => {
  return useMutation((id) => DETAIL_ACTIVITY.deleteDetailActivity(id));
};
