import React from "react";
import { Label } from "components";
import { InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";
import AnswerItem from "./answerItem";
import { map } from "lodash";

const IndicatorForm = () => {
  const { register, getValues, watch } = useFormContext();

  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Coverage Area</Label>
        <InputForm
          {...register("coverageArea", {
            required: {
              value: true,
              message: "Indicator is required",
            },
          })}
          className="w-full"
          name="indicator"
        />
      </div>
      <div className="space-y-2">
        <Label>Type</Label>
        <div className="flex space-x-6">
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("type")}
              name="type"
              value="option"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Option
            </span>
          </label>
          <label className="flex space-x-2 items-center flex-1">
            <input
              type="radio"
              className="peer border-gray-300 checked:bg-primary-600 checked:hover:bg-primary-600 checked:active:bg-primary-600 checked:focus:bg-primary-600 focus:bg-primary-600 focus:outline-none focus:ring-1 focus:ring-primary-600"
              {...register("type")}
              name="type"
              value="multiple-select"
            />
            <span className="text-gray-700 peer-checked:font-semibold">
              Multiple Select
            </span>
          </label>
        </div>
      </div>
      {watch("type") && (
        <div className="space-y-2">
          <Label>Answer Value</Label>
          <AnswerItem />
        </div>
      )}
    </div>
  );
};

export default IndicatorForm;
