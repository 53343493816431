import React, { useCallback, useMemo, useState } from "react";

import { BasicList } from "components/ListPage";

import LemtaraUser from "./LemtaraUser";
import GroupUser from "./GroupUser";
import IndividualUser from "./IndividualUser";
import InstitutionUser from "./InstitutionUser";
import MinistryUser from "./MinistryUser";
import GrantRecipientsUser from "./GrantRecipientsUser";
import DonorUser from "./DonorUser";

const UserExternal = () => {
  const [activeTab, setActiveTab] = useState(0);
  const links = [
    {
      path: "/user-management",
      label: "User Management",
    },
    {
      label: "User External",
    },
  ];

  const tabHead = useMemo(
    () => [
      "LEMTARA",
      "Kelompok",
      "Perorangan",
      "Lembaga",
      "Kementrian Lembaga/Negara",
      "Penerima Dana Hibah",
      "Donor",
    ],
    []
  );

  const tabBody = useMemo(
    () => [
      <LemtaraUser />,
      <GroupUser />,
      <IndividualUser />,
      <InstitutionUser />,
      <MinistryUser />,
      <GrantRecipientsUser />,
      <DonorUser />,
    ],
    []
  );

  const result = useMemo(() => {
    return [
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "name",
        title: "Nama",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "email",
        title: "Email",
        dataIndex: "email",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "username",
        title: "Username",
        dataIndex: "username",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "position",
        title: "Jabatan",
        dataIndex: "position",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "directorate",
        title: "Direktorat",
        dataIndex: "directorate",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <BasicList
      links={links}
      title={"Daftar User External"}
      columns={tableColumns}
      dataSource={result}
      isTabView={true}
      tabActive={activeTab}
      onChangeTab={setActiveTab}
      tabHead={tabHead}
      tabBody={tabBody}
      // isLoading={status === "loading"}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      pagination={paginator}
    />
  );
};

export default UserExternal;
