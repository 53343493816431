import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { filter, findIndex, last } from "lodash";

import { Breadcrumbs, Button, Card, CardBody, CardHeader } from "components";
import { FormModal } from "components/Modal";
import CoverageAreaForm from "./coverageAreaForm";
import CoverageAreaItem from "./coverageAreaItem";
import InformationForm from "./informationForm";
import { useNavigate } from "react-router";

const InvestmentCriteriaForm = () => {
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      investmnetCriteria: "",
      description: "",
      coverageAreas: [],
    },
  });

  const coverageAreaMethods = useForm({
    defaultValues: {
      coverageArea: "",
      type: "",
      answerValue: [
        {
          value: "",
        },
      ],
    },
  });

  const [coverageAreaModal, setCoverageAreaModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const lastObj = last(methods.getValues("coverageAreas"));
    if (lastObj && !coverageAreaModal) {
      let newFormatNumber = parseFloat(
        parseFloat(lastObj?.format_number?.replace("#", "")) + 0.1
      ).toFixed(1);
      coverageAreaMethods.reset({
        format_number: `#${newFormatNumber}`,
        coverageArea: "",
        description: "",
      });
    }

    !coverageAreaModal && setIsEdit(false);

    return;
  }, [methods.getValues("coverageAreas"), coverageAreaModal]);

  const links = [
    {
      path: "/investment-criteria",
      label: "Investment Strategy",
    },
    {
      path: "/investment-strategy/investment-criteria",
      label: "Investment Criteria",
    },
    {
      label: "Tambah Investment Criteria",
    },
  ];

  const onCreateCoverageArea = (payload) => {
    if (isEdit) {
      let coverageAreasData = [...methods.getValues("coverageAreas")];
      const index = findIndex(
        coverageAreasData,
        (coverageArea) => coverageArea?.format_number === payload?.format_number
      );
      coverageAreasData[index] = payload;
      methods.reset({ coverageAreas: coverageAreasData });
    } else {
      methods.setValue("coverageAreas", [
        ...methods.getValues("coverageAreas"),
        payload,
      ]);
    }
    setCoverageAreaModal(false);
  };

  const onEditCoverageArea = (data) => {
    setIsEdit(true);
    coverageAreaMethods.reset({
      ...data,
    });
    setCoverageAreaModal(true);
  };

  const onDeleteCoverageArea = (data) => {
    const newData = filter(
      methods.getValues("coverageAreas"),
      (coverageArea) => coverageArea?.format_number !== data?.format_number
    );
    methods.reset({ coverageAreas: newData });
  };

  return (
    <div>
      <FormModal
        title={isEdit ? "Edit Coverage Area" : "Tambah Coverage Area"}
        open={coverageAreaModal}
        onClose={setCoverageAreaModal}
        onSubmit={() =>
          coverageAreaMethods.handleSubmit(onCreateCoverageArea)()
        }
      >
        <FormProvider {...coverageAreaMethods}>
          <CoverageAreaForm />
        </FormProvider>
      </FormModal>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <Card>
            <CardHeader>
              <div>Tambah Investment Criteria</div>
            </CardHeader>
            <CardBody>
              <InformationForm />
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div className="flex items-center justify-between">
                <div>Coverage Area</div>
                <div>
                  <Button
                    className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                    startIcon={<AiOutlinePlus className="text-white" />}
                    onClick={() => setCoverageAreaModal(true)}
                  >
                    Tambah Coverage Area
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <CoverageAreaItem
                datas={methods.getValues("coverageAreas")}
                onEdit={onEditCoverageArea}
                onDelete={onDeleteCoverageArea}
              />
            </CardBody>
          </Card>
          <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-60"
              onClick={() => navigate(-1)}
            >
              Batal
            </Button>
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 w-60"
            >
              Simpan
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default InvestmentCriteriaForm;
