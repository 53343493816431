import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { BasicList } from "components/ListPage";
import { AiOutlineSync } from "react-icons/ai";

const MinistryUser = () => {
  const [activeTab, setActiveTab] = useState(0);
  const links = [
    {
      path: "/user-management",
      label: "User Management",
    },
    {
      label: "User External",
    },
  ];

  const tabHead = useMemo(
    () => [
      "LEMTARA",
      "Kelompok",
      "Perorangan",
      "Lembaga",
      "Kementrian Lembaga/Negara",
      "Penerima Dana Hibah",
      "Donor",
    ],
    []
  );

  const tabBody = useMemo(
    () => [
      "LEMTARA",
      "Kelompok",
      "Perorangan",
      "Lembaga",
      "Kementrian Lembaga/Negara",
      "Penerima Dana Hibah",
      "Donor",
    ],
    []
  );

  const result = useMemo(() => {
    return [
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
      {
        name: "Nama User",
        email: "namauser@mail.com",
        username: "username01",
        position: "Jabatan1",
        directorate: "Direktorat Jabatan",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "name",
        title: "Nama",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "email",
        title: "Email",
        dataIndex: "email",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "username",
        title: "Username",
        dataIndex: "username",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "position",
        title: "Jabatan",
        dataIndex: "position",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "directorate",
        title: "Direktorat",
        dataIndex: "directorate",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <BasicList
      links={links}
      title={"Daftar User Kementrian Lembaga/Negara"}
      columns={tableColumns}
      dataSource={result}
      isTabChild={true}
      // isLoading={status === "loading"}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      pagination={paginator}
    />
  );
};

export default MinistryUser;