import ReactDOM from "react-dom";
import { Button, TitleText } from "components";
import { motion } from "framer-motion";
import { CgClose } from "react-icons/cg";

const FormModal = ({
  title = "",
  open,
  onClose,
  onSubmit,
  children,
  hasFooter = true,
}) => {
  if (!open) return;

  return ReactDOM.createPortal(
    <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-[999]">
      <motion.div
        key="modal-confirmation"
        className="bg-white rounded-xl w-1/2 max-h-screen"
        initial={{ scale: 0 }}
        animate={{ scale: 1, animation: 10 }}
        exit={{ scale: 0 }}
      >
        <div className="flex flex-col py-4 space-y-4">
          <div className="flex items-center justify-between px-8">
            <TitleText className="">{title}</TitleText>
            <CgClose
              className="text-xl cursor-pointer"
              onClick={() => onClose(false)}
            />
          </div>

          <div className="max-h-[calc(100vh-10.375rem)] border-y overflow-y-scroll px-8 py-4">
            {children}
          </div>

          {hasFooter && (
            <div className="flex items-center space-x-4 px-8">
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                onClick={() => onClose(false)}
              >
                Batal
              </Button>
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
                onClick={onSubmit}
              >
                Simpan
              </Button>
            </div>
          )}
        </div>
      </motion.div>
    </div>,
    document.body
  );
};

export default FormModal;
