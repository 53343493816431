import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import { BasicList } from "components/ListPage";

const InvestmentCriteria = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);

  const links = [
    {
      path: "/investment-criteria",
      label: "Investment Strategy",
    },
    {
      label: "Investment Criteria",
    },
  ];

  const result = useMemo(() => {
    return [
      {
        code: 12,
        impact: "Investment Criteria A",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 142,
        impact: "Investment Criteria B",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 112,
        impact: "Investment Criteria C",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 512,
        impact: "Investment Criteria D",
        description: "Lorem ipsum Dolor Sit Amet",
      },
      {
        code: 162,
        impact: "Investment Criteria E",
        description: "Lorem ipsum Dolor Sit Amet",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "impact",
        title: "InvestmentCriteria",
        dataIndex: "impact",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "description",
        title: "Deskripsi",
        dataIndex: "description",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() =>
                navigate(`/investment-strategy/investment-criteria/detail`)
              }
            />

            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() =>
                navigate(`/investment-strategy/investment-criteria/form`)
              }
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              // onClick={() =>
              //   navigate(`/investment-strategy/investment-criteria/detail`)
              // }
            />
          </div>
        ),
      },
    ];
  }, []);

  return (
    <BasicList
      links={links}
      title={"Investment Criteria"}
      onCreate={() => navigate("/investment-strategy/investment-criteria/form")}
      onSearch={onSearch}
      columns={tableColumns}
      dataSource={result}
      // isLoading={status === "loading"}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      pagination={paginator}
    />
  );
};

export default InvestmentCriteria;
