import React, { useCallback, useMemo } from "react";
import { enqueueSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { RiSave3Line } from "react-icons/ri";
import { useNavigate } from "react-router";

import { Breadcrumbs, Button, Card, CardBody, CardHeader } from "components";
import { usePostDetailActivity } from "hooks/detailActivity";
import LoadingButton from "components/LoadingButton";

import DetailActivityForm from "./Form";

const breadcrumbs = [
  {
    path: "/master-data",
    label: "Master Data",
  },
  {
    path: "/master-data/detail-activity",
    label: "Detail Kegiatan",
  },
  {
    label: "Buat Detail Kegiatan",
  },
];

const DetailActivityCreate = () => {
  const navigate = useNavigate();

  const formDefaultValues = useMemo(() => {
    return {
      code: "",
      detail: "",
      idKegiatanUtama: {
        value: "",
        label: "",
      },
      deskripsi: "",
      accountType: {
        value: "",
        label: "",
      },
    };
  }, []);

  const form = useForm({
    defaultValues: formDefaultValues,
  });

  const postDetailActivity = usePostDetailActivity();

  const handleFormCancel = () => {
    form.reset(formDefaultValues);
    navigate(-1);
  };

  const handleFormSubmit = useCallback(
    async (data) => {
      if (isEmpty(data)) {
        return;
      }

      try {
        await postDetailActivity.mutateAsync({
          code: data.code,
          detail: data.detail,
          deskripsi: data.deskripsi,
          accountType: data.accountType.value,
          idKegiatanUtama: data.idKegiatanUtama.value,
        });

        form.reset(formDefaultValues);

        enqueueSnackbar({
          message: "Detail Kegiatan berhasil ditambahkan",
          variant: "success",
        });

        setTimeout(() => {
          navigate("/master-data/detail-activity");
        }, 300);
      } catch (error) {
        enqueueSnackbar({
          message: typeof error === "string" ? error : error?.message,
          variant: "error",
        });
      }
    },
    [form, formDefaultValues, postDetailActivity, navigate]
  );

  return (
    <div className="pt-12 space-y-6">
      <Breadcrumbs items={breadcrumbs} />

      <Card>
        <CardHeader>Tambah Detail Kegiatan</CardHeader>
        <CardBody>
          <FormProvider {...form}>
            <DetailActivityForm mode="create">
              <div className="flex-1 flex justify-between items-center space-x-4">
                <Button
                  type="button"
                  className="w-1/2 lg:w-60 text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                  onClick={handleFormCancel}
                >
                  Batal
                </Button>
                <LoadingButton
                  type="button"
                  className="w-1/2 lg:w-60 button-primary with-loading"
                  endIcon={<RiSave3Line className="text-white" size={20} />}
                  onClick={form.handleSubmit(handleFormSubmit)}
                  isLoading={postDetailActivity.status === "loading"}
                >
                  Simpan
                </LoadingButton>
              </div>
            </DetailActivityForm>
          </FormProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default DetailActivityCreate;
