import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";

import { BasicList } from "components/ListPage";
import { FormModal } from "components/Modal";

import SourceOfFundsForm from "./Form";
import { Button, MainPurposeBadge } from "components";
import Filter from "./Filter";

const FinantialRatioValue = () => {
  const navigate = useNavigate();
  const links = [
    {
      path: "/master-data",
      label: "Master Data",
    },
    {
      label: "Pengaturan Nilai Ratio Keuangan",
    },
  ];

  const onEdit = useCallback(() => {});

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const result = useMemo(() => {
    return [
      {
        code: 12,
        ratio_type: "Rasio Profitabilitas",
        ratio_count: 2,
        standard_min_value: "-",
        main_purpose: {
          id: 1,
          name: "Mencari Keuntungan",
        },
      },
      {
        code: 12,
        ratio_type: "Rasio Likuiditas",
        ratio_count: 2,
        standard_min_value: "-",
        main_purpose: {
          id: 2,
          name: "Bukan Mencari Keuntungan",
        },
      },
      {
        code: 12,
        ratio_type: "Rasio Solvabilitas",
        ratio_count: 2,
        standard_min_value: "-",
        main_purpose: {
          id: 1,
          name: "Mencari Keuntungan",
        },
      },
      {
        code: 12,
        ratio_type: "Rasio Kinerja Fiskal",
        ratio_count: 2,
        standard_min_value: 10000,
        main_purpose: {
          id: 2,
          name: "Bukan Mencari Keuntungan",
        },
      },
      {
        code: 12,
        ratio_type: "Rasio Profitabilitas",
        ratio_count: 2,
        standard_min_value: 2000,
        main_purpose: {
          id: 1,
          name: "Mencari Keuntungan",
        },
      },
    ];
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "ratio_type",
        title: "Rasio Profitabilitas",
        dataIndex: "ratio_type",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "ratio_count",
        title: "Jumlah Rasio yang Dinilai",
        dataIndex: "ratio_count",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "standard_min_value",
        title: "Standar Nilai Minimum",
        dataIndex: "standard_min_value",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "main_purpose",
        title: "Tujuan Utama Lembaga",
        dataIndex: "main_purpose",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => <MainPurposeBadge data={value} />,
      },
      {
        id: "actions",
        title: () => <div className="text-center">Action</div>,
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="max-w-[240px] flex justify-center">
            {index === 0 || index === 1 ? (
              <Button onClick={() => navigate("/master-data/financial-ratio-value/set-value")} className="w-full text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 h-fit min-h-fit py-2">
                Atur Nilai Ratio
              </Button>
            ) : (
              <BiPencil
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onEdit(data)}
              />
            )}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <BasicList
      links={links}
      title={"Pengaturan Nilai Ratio Keuangan"}
      columns={tableColumns}
      dataSource={result}
      customFilter={<Filter />}
      // isLoading={status === "loading"}
      // onChangePage={setNewPage}
      // onChangeRowsPerPage={setNewLimit}
      pagination={paginator}
    />
  );
};

export default FinantialRatioValue;
