import React, { useCallback } from "react";
import { CustomSelect, SearchInput } from "components";

const Filter = ({
  onSearch,
  typeOptions = [],
  onChangeType,
}) => {
  return (
    <div className="space-x-5 flex">
      <SearchInput placeholder="Cari" onChange={onSearch} />
      <CustomSelect
        optionsData={typeOptions}
        placeholder="Tujuan Utama Lembaga"
        className="flex-1 rounded-md border-gray-200 border-solid text-gray-400 text-sm outline-none focus:outline-none"
        onChange={onChangeType}
      />
    </div>
  );
};

export default Filter;
