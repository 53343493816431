import { CustomSelectForm, InputForm } from "components/HookForm";
import { Label } from "components";
import { getAccountTypeValue } from "helpers";
import { accountType } from "constants/options";
import { useActivityList } from "hooks/mainActivity";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDetailActivityList } from "hooks/detailActivity";
import { useFormContext } from "react-hook-form";

const BudgetAccountForm = ({ children, mode = "create", detail }) => {
  const isDetailMode = !["create", "edit"].includes(mode);

  const { register, setValue: setFormValue, watch } = useFormContext();

  const isInitialLoad = useRef(true);
  const selectedMainActivity = watch("kegiatanUtama");

  const { data: dataMainActivity } = useActivityList(["main-activity/list"]);
  const { data: dataDetailActivity } = useDetailActivityList([
    "detail-activity/list",
  ]);

  const mainActivities = useMemo(() => {
    if (dataMainActivity?.data.length) {
      return dataMainActivity?.data.map((item) => ({
        label: item.kegiatanUtama,
        value: item.id,
      }));
    }

    return [];
  }, [dataMainActivity?.data]);

  const detailActivities = useMemo(() => {
    if (dataDetailActivity?.data.length) {
      return dataDetailActivity?.data
        .filter((item) => item.idKegiatanUtama === selectedMainActivity?.value)
        .map((item) => ({
          label: item.detail,
          value: item.id,
        }));
    }

    return [];
  }, [dataDetailActivity?.data, selectedMainActivity?.value]);

  const setInitialFormValues = useCallback(() => {
    if (
      isInitialLoad.current &&
      mode === "edit" &&
      detailActivities.length &&
      detail?.idDetailKegiatan
    ) {
      isInitialLoad.current = false;

      setFormValue(
        "idDetailKegiatan",
        detailActivities.find(({ value }) => {
          return value === detail?.idDetailKegiatan;
        })
      );
    }
  }, [detailActivities, detail?.idDetailKegiatan, mode, setFormValue]);

  // reset filed detail activity
  useEffect(() => {
    if (!isInitialLoad.current && selectedMainActivity) {
      setFormValue("idDetailKegiatan", null);
      return;
    }

    setInitialFormValues();
  }, [setInitialFormValues, setFormValue, selectedMainActivity]);

  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required={!isDetailMode}>Kode</Label>
        {!isDetailMode ? (
          <InputForm
            className="w-full"
            {...register("code", {
              validate: (value) => {
                return (value ?? "").trim() !== "" || "Kode Wajib Diisi";
              },
            })}
          />
        ) : (
          <p>{detail.code}</p>
        )}
      </div>

      <div className="space-y-2 flex-1">
        <Label required={!isDetailMode}>Kegiatan Utama</Label>
        {!isDetailMode ? (
          <CustomSelectForm
            placeholder="Pilih Kegiatan Utama"
            options={mainActivities}
            {...register("kegiatanUtama", {
              required: {
                value: true,
                message: "Kegiatan Utama Wajib Diisi",
              },
              validate: ({ value }) => !!value || "Kegiatan Utama Wajib Diisi",
            })}
          />
        ) : (
          <p>{detail.kegiatanUtama}</p>
        )}
      </div>

      <div className="space-y-2 flex-1">
        <Label required>Detail Kegiatan</Label>
        {!isDetailMode ? (
          <CustomSelectForm
            name="idDetailKegiatan"
            placeholder="Pilih Detail Kegiatan"
            options={detailActivities}
            {...register("idDetailKegiatan", {
              required: {
                value: true,
                message: "Detail Kegiatan Wajib Diisi",
              },
              validate: ({ value }) => !!value || "Detail Kegiatan Wajib Diisi",
            })}
          />
        ) : (
          <p>{detail.detailKegiatan}</p>
        )}
      </div>

      <div className="space-y-2 flex-1">
        <Label required={!isDetailMode}>Mata Anggaran</Label>
        {!isDetailMode ? (
          <InputForm
            className="w-full"
            placeholder="Mata Anggaran"
            {...register("nama", {
              validate: (value) => {
                return (
                  (value ?? "").trim() !== "" || "Mata Anggaran Wajib Diisi"
                );
              },
            })}
          />
        ) : (
          <p>{detail.nama}</p>
        )}
      </div>

      <div className="space-y-2 flex-1">
        <Label required>Tipe COA</Label>
        {!isDetailMode ? (
          <CustomSelectForm
            placeholder="Pilih Tipe COA"
            options={accountType}
            {...register("accountType", {
              required: {
                value: true,
                message: "Tipe COA Wajib Diisi",
              },
              validate: ({ value }) => !!value || "Tipe COA Wajib Diisi",
            })}
          />
        ) : (
          <p>{getAccountTypeValue(accountType, detail.accountType)}</p>
        )}
      </div>

      {isDetailMode && (
        <div className="flex justify-between">
          <div className="w-1/2 space-y-1">
            <Label>Level</Label>
            <p className="text-sm text-gray-800">{detail?.level}</p>
          </div>
          <div className="w-1/2 space-y-1">
            <Label>Full Code</Label>
            <p className="text-sm text-gray-800">{detail?.coaFullCode ?? ""}</p>
          </div>
        </div>
      )}

      <div className="space-y-2 flex-1">
        <Label>Deksripsi</Label>
        {!isDetailMode ? (
          <InputForm
            type="textarea"
            className="w-full"
            name="deskripsi"
            rows="5"
          />
        ) : (
          <p>{detail.deskripsi}</p>
        )}
      </div>

      {children}
    </div>
  );
};

export default BudgetAccountForm;
