import { useMutation, useQuery } from '@tanstack/react-query';
import { IMPACT } from 'services';

export const useImpact = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return IMPACT.getImpacts({ ...params }, signal);
    },
    ...options,
  });
};

export const useImpactDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const id = queryKey[1];
      return IMPACT.getImpactById(id, signal);
    },
    ...options,
  });
};

export const useImpacts = () => {
  return useMutation((payload) => IMPACT.getDatatableImpacts(payload));
};

export const usePostImpact = () => {
  return useMutation((payload) => IMPACT.postImpact(payload));
};

export const useUpdateImpact = () => {
  return useMutation((payload) => IMPACT.updateImpact(payload));
};

export const useDeleteImpact = () => {
  return useMutation((id) => IMPACT.deleteImpact(id));
};
