import { UserManager, WebStorageStateStore } from "oidc-client";

const userManager = new UserManager({
  authority: process.env.REACT_APP_AUTH_URL,
  //automaticSilentRenew: false,
  client_id: "web_app_internal",
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: process.env.REACT_APP_SIGNOUT_REDIRECT_URL,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  response_mode: "query",
  response_type: "code",
  scope: "openid profile email mis.api",
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  stateStore: new WebStorageStateStore(),
  metadata: {
    issuer: `${process.env.REACT_APP_AUTH_URL}`,
    jwks_uri: `${process.env.REACT_APP_AUTH_URL}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/authorize`,
    token_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
    userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/userinfo`,
    end_session_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/endsession`,
    check_session_iframe: `${process.env.REACT_APP_AUTH_URL}/connect/checksession`,
    revocation_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/revocation`,
    introspection_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/introspect`,
  },
});

export default userManager;
