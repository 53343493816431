import { Breadcrumbs, Button, SearchInput, TitleText, Table } from "components";
import { map } from "lodash";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

const BasicList = ({
  links = [],
  title = "",
  columns = [],
  dataSource = [],
  isLoading = false,
  onCreate,
  onSearch,
  onChangePage,
  onChangeRowsPerPage,
  pagination = {},
  customFilter = null,
  btnCreateStartIcon = <AiOutlinePlus className="text-white" />,
  btnCreateLabel = "Tambah Data",
  isTabView = false,
  isTabChild = false,
  tabActive = 0,
  onChangeTab,
  tabHead = [],
  tabBody = [],
}) => {
  return (
    <div>
      {!isTabChild && <Breadcrumbs items={links} />}
      {isTabView && !isTabChild && (
        <div className="tabs mt-4 mb-6">
          {map(tabHead, (tab_head, key) => (
            <div
              key={key}
              onClick={() => onChangeTab(key)}
              className={`tab tab-bordered ${
                tabActive === key && "tab-active"
              }`}
            >
              {tab_head}
            </div>
          ))}
        </div>
      )}
      {isTabView && tabBody[tabActive]}
      {!isTabView && (
        <>
          <div
            className={`md:flex block items-center my-3 ${
              !Boolean(onCreate) && "my-5"
            }`}
          >
            <TitleText className="flex-1">{title}</TitleText>
            {Boolean(onCreate) && (
              <Button
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                startIcon={btnCreateStartIcon}
                onClick={onCreate}
              >
                {btnCreateLabel}
              </Button>
            )}
          </div>
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-3">
              <div className="space-y-4">
                {customFilter ? (
                  React.cloneElement(customFilter)
                ) : (
                  <div className="space-x-5 flex">
                    <SearchInput placeholder="Cari" onChange={onSearch} />
                  </div>
                )}
                <Table
                  bordered
                  stripped
                  // layout="fixed"
                  className="mb-4 px-5"
                  columns={columns}
                  dataSource={dataSource}
                  isLoading={isLoading}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  pagination={pagination}
                  style={{ overflowX: "auto" }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BasicList;
