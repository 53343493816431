import React from "react";
import { map } from "lodash";
import { BiPencil, BiTrash } from "react-icons/bi";

const IndicatorItem = ({ fields, onEdit, onDelete }) => {
  return (
    <div>
      {fields?.length > 0 ? (
        <div className="space-y-4 my-6">
          {map(fields, (data, key) => (
            <div
              className="border border-gray-200 rounded-md px-2 py-2 flex space-x-3"
              key={data.id}
            >
              <div className="flex flex-1 items-center space-x-2">
                <div className="text-sm text-gray-800 px-2">
                  {data?.numberingFormat}
                </div>
                <div className="flex flex-col flex-1 space-y-1">
                  <div className="text-sm text-gray-800">{data?.nama}</div>
                  <div className="text-xs text-gray-500">{data?.deskripsi}</div>
                  <div className="flex items-center space-x-2 pt-1">
                    {map(data?.parameter, (parameter, key) => (
                      <div className="text-[10px] rounded-full px-2 py-1 bg-blue-50 text-blue-700 font-medium">
                        {parameter?.nama}
                      </div>
                    ))}
                  </div>
                </div>
              </div> 
              <div className="flex flex-none items-center space-x-3 pr-2">
                <BiPencil
                  className="flex-1 text-gray-600 cursor-pointer"
                  onClick={() => onEdit(data)}
                />
                <BiTrash
                  className="flex-1 text-gray-600 cursor-pointer"
                  onClick={() => onDelete(key)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-400 my-8">
          No data has been added yet
        </div>
      )}
    </div>
  );
};

export default IndicatorItem;