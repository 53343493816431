import React, { forwardRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import ReactSelect from "react-select";

const CustomSelectForm = forwardRef(
  ({ placeholder = "Cari ...", validation = {}, ...props }, ref) => {
    const { name } = props;
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={validation}
        render={({
          field: { onChange, value, onBlur, ref: fieldRef },
          fieldState: { invalid, error },
        }) => (
          <div className="w-full">
            <ReactSelect
              {...props}
              ref={ref || fieldRef} // Either use the forwarded ref or the Controller's ref
              classNamePrefix="custom-select-form"
              placeholder={placeholder}
              value={value}
              onChange={onChange} // Pass onChange directly
              onBlur={onBlur}
              menuPlacement="auto"
              styles={{
                input: (styles) => ({
                  ...styles,
                  border: "0px",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: "#98A2B3",
                  fontSize: 14,
                  fontWeight: 400,
                }),
                control: (styles, { isFocused }) => ({
                  ...styles,
                  borderColor: invalid
                    ? "#dc3545"
                    : isFocused
                    ? "#01A24A"
                    : "#EAECF0",
                  ":hover": {
                    borderColor: invalid ? "#dc3545" : "#01A24A",
                  },
                  boxShadow: "none",
                  borderRadius: "6px",
                }),
                indicatorContainer: (styles) => ({
                  ...styles,
                  ":hover": {
                    color: "#98A2B3",
                  },
                }),
                option: (styles, { isSelected, isFocused, isDisabled }) => ({
                  ...styles,
                  cursor: isDisabled ? "not-allowed" : "auto",
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? "#01A24A"
                    : isFocused
                    ? "#EEFFF4"
                    : undefined,
                  color: isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : isSelected
                    ? "#fff"
                    : isFocused
                    ? "#98A2B3"
                    : undefined,
                }),
              }}
              menuPortalTarget={document.body}
            />
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </div>
        )}
      />
    );
  }
);

export default React.memo(CustomSelectForm);
