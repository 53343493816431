import React from "react";
import { useFormContext } from "react-hook-form";

import { accountType } from "constants";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";

const InformationForm = () => {
  const { register } = useFormContext();

  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Format Penomoran</Label>
        <InputForm
          className="w-full"
          {...register("numberingFormat", {
            validate: (value) => {
              return value.trim() !== "" || "Format Penomoran Wajib Diisi";
            },
          })}
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label required>Outcome</Label>
        <InputForm
          className="w-full"
          {...register("nama", {
            validate: (value) => {
              return value.trim() !== "" || "Outcome Wajib Diisi";
            },
          })}
        />
      </div>
      <div className="flex gap-4">
        <div className="space-y-2 flex-1">
          <Label required>Kode COA</Label>
          <InputForm
            className="w-full"
            {...register("coaCode", {
              validate: (value) => {
                return value.trim() !== "" || "Kode COA Wajib Diisi";
              },
            })}
          />
        </div>
        <div className="space-y-2 flex-1">
          <Label required>Tipe COA</Label>
          <CustomSelectForm
            {...register("accountType", {
              required: {
                value: true,
                message: "Tipe COA Wajib Diisi",
              },
            })}
            placeholder="Type"
            options={accountType}
          />
        </div>
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="deskripsi" />
      </div>
    </div>
  );
};

export default InformationForm;
