import http from 'app/interceptors';

export const getBudgetAccountsList = async (signal) => {
  const { data } = await http.get(`/BudgetAccount/list`, { signal });
  return data;
};

export const getBudgetAccounts = async (payload) => {
  const { data } = await http.post(`/BudgetAccount/datatable`, payload);
  return data;
};

export const getBudgetAccountById = async (id, signal) => {
  const { data } = await http.get(`/BudgetAccount/${id}`, { signal });
  return data;
};

export const postBudgetAccount = async (payload) => {
  const { data } = await http.post(`/BudgetAccount`, payload);
  return data;
};

export const updateBudgetAccount = async (payload) => {
  const { data } = await http.put(`/BudgetAccount`, payload);
  return data;
};

export const deleteBudgetAccount = async (id, signal) => {
  const { data } = await http.delete(`/BudgetAccount`, {
    params: { id },
    signal,
  });
  return data;
};
