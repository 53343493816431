import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";

import { Breadcrumbs, Card, CardBody, CardHeader } from "components";
import { useBudgetAccountDetail } from "hooks/budgetAccount";

import BudgetActivityForm from "./Form";

const breadcrumbs = [
  {
    path: "/master-data",
    label: "Master Data",
  },
  {
    path: "/master-data/budget-account",
    label: "Mata Anggaran",
  },
  {
    label: "Detail Mata Anggaran",
  },
];

const BudgetAccountDetail = () => {
  const { id } = useParams();

  const { data } = useBudgetAccountDetail([`budget-account/${id}`, id]);

  const form = useForm({
    defaultValues: {},
  });

  return (
    <div className="pt-12 space-y-6">
      <Breadcrumbs items={breadcrumbs} />

      <Card>
        <CardHeader>Detail Mata Anggaran</CardHeader>
        <CardBody>
          <FormProvider {...form}>
            <BudgetActivityForm mode="detail" detail={data?.data ?? {}} />
          </FormProvider>
        </CardBody>
      </Card>
    </div>
  );
};

export default BudgetAccountDetail;
