import React, { useEffect, useState } from "react";

import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
  TitleText,
} from "components";
import { CgArrowLeft } from "react-icons/cg";
import { useNavigate, useParams } from "react-router";
import { useImpactDetail } from "hooks/impact";
import { last, map } from "lodash";
import classNames from "classnames";

const ImpactDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { status, data } = useImpactDetail([`impact/detail/${id}`, id]);
  const links = [
    {
      path: "/theory-of-change",
      label: "Theory of Change",
    },
    {
      path: "/theory-of-change/impact",
      label: "Impact",
    },
    {
      label: data?.data.nama ?? "Detail",
    },
  ];
  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <span
        className="flex items-center space-x-2 cursor-pointer text-gray-600"
        onClick={() => navigate(-1)}
      >
        <CgArrowLeft className="text-lg" />
        <p className="font-bold text-sm">Back to Impact</p>
      </span>
      <Card>
        <CardHeader>
          <div>Detail</div>
        </CardHeader>

        <CardBody>
          {status === "loading" ? (
            <div className="h-32 w-full">
              <Spinner />
            </div>
          ) : (
            <div className="space-y-5">
              <div className="flex items-center">
                <div className="flex-1 space-y-1">
                  <Label>Impact</Label>
                  <p className="text-sm text-gray-800">{data?.data?.nama}</p>
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Format Penomoran</Label>
                  <p className="text-sm text-gray-800">
                    {" "}
                    {data?.data?.numberingFormat}
                  </p>
                </div>
              </div>
              <div className="space-y-1">
                <Label>Deskripsi</Label>
                <p className="text-sm text-gray-800">{data?.data?.deskripsi}</p>
              </div>
              <div className="space-y-5">
                <TitleText className="text-primary-700 font-bold">
                  Indicator
                </TitleText>
                <div className="overflow-x-auto overflow-y-hidden">
                  <table className="table w-full text-gray-600">
                    <thead className="border-b">
                      <tr>
                        <th className="bg-primary-50">No</th>
                        <th className="bg-primary-50">Indicator</th>
                        <th className="bg-primary-50">Deskripsi</th>
                        <th className="bg-primary-50">Parameter</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(data?.data?.indicator, (indicator) => (
                        <tr className="border-b">
                          <td className="sticky left-0 z-10">
                            {indicator?.numberingFormat ?? "-"}
                          </td>
                          <td>{indicator?.nama ?? "-"}</td>
                          <td>{indicator?.deskripsi ?? "-"}</td>
                          <td>
                            {indicator?.parameter?.length > 0
                              ? map(
                                  indicator?.parameter,
                                  (parameter, index, { length }) => {
                                    return (
                                      <div
                                        className={classNames(
                                          "p-2",
                                          index + 1 !== length ? "border-b" : ""
                                        )}
                                      >
                                        {parameter.nama} ({parameter.satuan})
                                      </div>
                                    );
                                  }
                                )
                              : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ImpactDetail;
