import React, { useEffect, useState } from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const InterventionForm = () => {
  const { register } = useFormContext();
  return (
    <div className="space-y-5">
      <div className="space-y-2 flex-1">
        <Label required>Fokus Isu</Label>
        <InputForm
          {...register("intervention", {
            required: {
              value: true,
              message: "Fokus Isu is required",
            },
          })}
          className="w-full"
          name="intervention"
        />
      </div>
      <div className="space-y-2 flex-1">
        <Label>Deskripsi</Label>
        <InputForm type="textarea" className="w-full" name="description" />
      </div>
    </div>
  );
};

export default InterventionForm;
