import MainPage from "pages";

import Impact from "pages/TheoryOfChange/Impact";
import ImpactDetail from "pages/TheoryOfChange/Impact/Detail";
import ImpactForm from "pages/TheoryOfChange/Impact/Form";
import Outcome from "pages/TheoryOfChange/Outcome";
import OutcomeDetail from "pages/TheoryOfChange/Outcome/Detail";
import OutcomeForm from "pages/TheoryOfChange/Outcome/Form";
import Output from "pages/TheoryOfChange/Output";
import OutputDetail from "pages/TheoryOfChange/Output/Detail";
import OutputForm from "pages/TheoryOfChange/Output/Form";
import Indicator from "pages/TheoryOfChange/Indicator";

import ThematicProgrammes from "pages/ThematicProgrammes";
import ThematicProgrammesForm from "pages/ThematicProgrammes/Form";
import ThematicProgrammesDetail from "pages/ThematicProgrammes/Detail";
import IndicativeActivity from "pages/ThematicProgrammes/IndicativeActivity";
import IndicativeActivityForm from "pages/ThematicProgrammes/IndicativeActivity/Form";
import IndicativeActivityDetail from "pages/ThematicProgrammes/IndicativeActivity/Detail";

import SourceOfFunds from "pages/InvestmentStrategy/SourceOfFunds";
import InvestmentCriteria from "pages/InvestmentStrategy/InvestmentCriteria";
import InvestmentCriteriaForm from "pages/InvestmentStrategy/InvestmentCriteria/Form";
import InvestmentCriteriaDetail from "pages/InvestmentStrategy/InvestmentCriteria/Detail";
import FinancialInstrument from "pages/InvestmentStrategy/FinancialInstrument";

import CapacityAssessment from "pages/MasterData/CapacityAssessment";
import CapacityAssessmentForm from "pages/MasterData/CapacityAssessment/Form";
import CooperationArea from "pages/MasterData/CooperationArea";
import CooperationAreaForm from "pages/MasterData/CooperationArea/Form";
import InstitutionType from "pages/MasterData/InstitutionType";
import InstitutionTypeForm from "pages/MasterData/InstitutionType/Form";
import Intervention from "pages/MasterData/Intervention";
import InterventionForm from "pages/MasterData/Intervention/Form";
import StrategicPlan from "pages/MasterData/StrategicPlan";
import StrategicPlanForm from "pages/MasterData/StrategicPlan/Form";
import FinancialRatioValue from "pages/MasterData/FinancialRatioValue";
import FinancialRatioValueSetValue from "pages/MasterData/FinancialRatioValue/Form";
import MainActivity from "pages/MasterData/MainActivity";
import MainActivityCreate from "pages/MasterData/MainActivity/create";
import MainActivityDetail from "pages/MasterData/MainActivity/detail";
import MainActivityEdit from "pages/MasterData/MainActivity/edit";
import DetailActivity from "pages/MasterData/DetailActivity";
import DetailActivityCreate from "pages/MasterData/DetailActivity/create";
import DetailActivityDetail from "pages/MasterData/DetailActivity/detail";
import DetailActivityEdit from "pages/MasterData/DetailActivity/edit";
import BudgetAccount from "pages/MasterData/BudgetAccount";
import BudgetAccountCreate from "pages/MasterData/BudgetAccount/create";
import BudgetAccountDetail from "pages/MasterData/BudgetAccount/detail";
import BudgetAccountEdit from "pages/MasterData/BudgetAccount/edit";

import UserInternal from "pages/UserManagement/UserInternal";
import UserExternal from "pages/UserManagement/UserExternal";
import ActivityLog from "pages/UserManagement/ActivityLog";

import routesGuest from "./route-guest";

const theoryOfChange = [
  {
    routeType: "public",
    path: "/theory-of-change/impact",
    element: Impact,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/impact/:id/detail",
    element: ImpactDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/impact/:id/form",
    element: ImpactForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/impact/form/:id",
    element: ImpactForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/impact/form",
    element: ImpactForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/outcome",
    element: Outcome,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/outcome/detail/:id",
    element: OutcomeDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/outcome/form",
    element: OutcomeForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/outcome/form/:id",
    element: OutcomeForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/output",
    element: Output,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/output/detail/:id",
    element: OutputDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/output/form",
    element: OutputForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/output/form/:id",
    element: OutputForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/theory-of-change/indicator",
    element: Indicator,
    exact: true,
  },
];

const thematicProgrammes = [
  {
    routeType: "public",
    path: "/thematic-programmes",
    element: ThematicProgrammes,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/form",
    element: ThematicProgrammesForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/form/:id",
    element: ThematicProgrammesForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/detail/:id",
    element: ThematicProgrammesDetail,
    exact: true,
  },
  // {
  //   routeType: "public",
  //   path: "/thematic-programmes/sub-theme",
  //   element: SubTheme,
  //   exact: true,
  // },
  // {
  //   routeType: "public",
  //   path: "/thematic-programmes/sub-theme/form",
  //   element: SubThemeForm,
  //   exact: true,
  // },
  {
    routeType: "public",
    path: "/thematic-programmes/indicative-activity",
    element: IndicativeActivity,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/indicative-activity/form",
    element: IndicativeActivityForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/indicative-activity/form/:id",
    element: IndicativeActivityForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/thematic-programmes/indicative-activity/detail/:id",
    element: IndicativeActivityDetail,
    exact: true,
  },
  // {
  //   routeType: "public",
  //   path: "/thematic-programmes/sub-indicative-activity",
  //   element: SubIndicativeActivity,
  //   exact: true,
  // },
  // {
  //   routeType: "public",
  //   path: "/thematic-programmes/sub-indicative-activity/form",
  //   element: SubIndicativeActivityForm,
  //   exact: true,
  // },
];

const investmentStrategy = [
  {
    routeType: "public",
    path: "/investment-strategy/source-of-funds",
    element: SourceOfFunds,
    exact: true,
  },
  {
    routeType: "public",
    path: "/investment-strategy/investment-criteria",
    element: InvestmentCriteria,
    exact: true,
  },
  {
    routeType: "public",
    path: "/investment-strategy/investment-criteria/form",
    element: InvestmentCriteriaForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/investment-strategy/investment-criteria/detail",
    element: InvestmentCriteriaDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/investment-strategy/investment-criteria",
    element: InvestmentCriteria,
    exact: true,
  },
  {
    routeType: "public",
    path: "/investment-strategy/financial-instrument",
    element: FinancialInstrument,
    exact: true,
  },
];

const masterData = [
  {
    routeType: "public",
    path: "/master-data/capacity-assessment",
    element: CapacityAssessment,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/capacity-assessment/form",
    element: CapacityAssessmentForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/cooperation-area",
    element: CooperationArea,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/cooperation-area/form",
    element: CooperationAreaForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/institution-type",
    element: InstitutionType,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/institution-type/form",
    element: InstitutionTypeForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/intervention",
    element: Intervention,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/intervention/form",
    element: InterventionForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/strategic-plan",
    element: StrategicPlan,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/strategic-plan/form",
    element: StrategicPlanForm,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/financial-ratio-value",
    element: FinancialRatioValue,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/financial-ratio-value/set-value",
    element: FinancialRatioValueSetValue,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/main-activity",
    element: MainActivity,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/main-activity/create",
    element: MainActivityCreate,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/main-activity/:id",
    element: MainActivityDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/main-activity/:id/edit",
    element: MainActivityEdit,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/detail-activity",
    element: DetailActivity,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/detail-activity/create",
    element: DetailActivityCreate,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/detail-activity/:id",
    element: DetailActivityDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/detail-activity/:id/edit",
    element: DetailActivityEdit,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/budget-account",
    element: BudgetAccount,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/budget-account/create",
    element: BudgetAccountCreate,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/budget-account/:id",
    element: BudgetAccountDetail,
    exact: true,
  },
  {
    routeType: "public",
    path: "/master-data/budget-account/:id/edit",
    element: BudgetAccountEdit,
    exact: true,
  },
];

const userManagement = [
  {
    routeType: "public",
    path: "/user-management/internal",
    element: UserInternal,
    exact: true,
  },
  {
    routeType: "public",
    path: "/user-management/external",
    element: UserExternal,
    exact: true,
  },
  {
    routeType: "public",
    path: "/user-management/activity-log",
    element: ActivityLog,
    exact: true,
  },
];

const routesName = [
  {
    routeType: "public",
    path: "/",
    element: MainPage,
    exact: true,
  },
  ...theoryOfChange,
  ...thematicProgrammes,
  ...investmentStrategy,
  ...masterData,
  ...userManagement,
  ...routesGuest,
];

export default routesName;
