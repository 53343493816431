import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleLogInAuthentication, removeToken } from "app/reducer/authSlice";
import { Button } from "components";
import { AUTH } from "services";

const SigninCallback = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.isLoading);
  const isLoggedIn = useSelector(({ auth }) => {
    return !!auth.user && auth.token;
  });

  const redirectUri = localStorage.getItem("redirectUri");

  const handleLogout = () => {
    dispatch(removeToken());
    AUTH.signoutRedirect();
  };

  useEffect(() => {
    dispatch(handleLogInAuthentication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
      );

      window.location = redirectUri;
    }
  }, [isLoading, isLoggedIn, redirectUri]);

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <p className="text-4xl text-center font-bold mb-1">
        Sedang mengauthentikasi session anda...
      </p>
      <p className="mb-6">
        Anda akan diarahkan secara otomatis ke halaman {redirectUri} jika
        session anda valid
      </p>

      <Button
        theme="link"
        className="text-green-500"
        onClick={handleLogout}
        disabled={isLoading}
      >
        Login Ulang
      </Button>
    </div>
  );
};

export default SigninCallback;
