import { Button } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const ConfirmationModal = ({ open, onClose, onSubmit }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="flex flex-col p-6 space-y-8">
              <div className="flex-1 flex items-center justify-between">
                <FiAlertCircle className="text-xl" />
                <CgClose className="text-xl" onClick={() => onClose(false)} />
              </div>
              <div className="flex-1">
                <div>Apakah anda yakin akan menghapus data berikut?</div>
              </div>
              <div className="flex-1 flex items-center space-x-4">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  onClick={() => onClose(false)}
                >
                  Tidak
                </Button>
                <Button
                  type="button"
                  className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700 flex-1"
                  onClick={onSubmit}
                >
                  Ya
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationModal;
